
    var commandTypes = {
        NotApplicable: 'NOTAPPLICABLE',
        Clear: 'CLEAR',
        Negate: 'NEGATE',
        LoadNumberConstant: 'LOADNUMBERCONSTANT',
        LoadStringConstant: 'LOADSTRINGCONSTANT',
        LoadVariable: 'LOADVARIABLE',
        Push: 'PUSH',
        PopAdd: 'POPADD',
        PopSubtract: 'POPSUBTRACT',
        PopMultiply: 'POPMULTIPLY',
        PopDivide: 'POPDIVIDE',
        PopAnd: 'POPAND',
        PopOr: 'POPOR',
        PopXor: 'POPXOR',
        PopCompare: 'POPCOMPARE',
        NotIt: 'NOTIT',
        SetEqual: 'SETEQUAL',
        SetNotEqual: 'SETNOTEQUAL',
        SetLess: 'SETLESS',
        SetLessOrEqual: 'SETLESSOREQUAL',
        SetGreater: 'SETGREATER',
        SetGreaterOrEqual: 'SETGREATEROREQUAL',
        Branch: 'BRANCH',
        BranchFalse: 'BRANCHFALSE',
        //EndProgram: 'ENDPROGRAM',
        EndProgram: 'END',
        ErrorMessage: 'ERRORMESSAGE',
        Label: 'LABEL',
        SquareRoot: 'SQUAREROOT',
        RoundNearest: 'ROUNDNEAREST',
        RoundUp: 'ROUNDUP',
        PopGreaterOf: 'POPGREATEROF',
        PopLesserOf: 'POPLESSEROF',
        Truncate: 'TRUNCATE',
        PrepForCompare: 'PREPFORCOMPARE'
    };

    var variableTypes = {
        NotApplicable: 'NOTAPPLICABLE',
        LoadingAdministrator: 'LOADINGADMINISTRATOR',
        LoadingTrailerAdministrator: 'LOADINGTRAILERADMINISTRATOR',
        TruckScienceAdministrator: 'TRUCKSCIENCEADMINISTRATOR',

        PropertiesApplication: 'PROPERTIESAPPLICATION',
        PropertiesApplyHigherMassLimits: 'PROPERTIESAPPLYHIGHERMASSLIMITS',
        PropertiesApplyConcessionalMassLimits: 'PROPERTIESAPPLYCONCESSIONALMASSLIMITS',

        VehicleAxleLayout: 'VEHICLEAXLELAYOUT',
        VehicleNoOfTyresAxle1Front: 'VEHICLENOOFTYRESAXLE1FRONT',
        VehicleNoOfTyresAxle2Front: 'VEHICLENOOFTYRESAXLE2FRONT',
        VehicleNoOfTyresAxle1Rear: 'VEHICLENOOFTYRESAXLE1REAR',
        VehicleNoOfTyresAxle2Rear: 'VEHICLENOOFTYRESAXLE2REAR',
        VehicleNoOfTyresAxle3Rear: 'VEHICLENOOFTYRESAXLE3REAR',
        VehicleNoOfTyresAxle4Rear: 'VEHICLENOOFTYRESAXLE4REAR',
        VehicleTyreWidthAxle1Front: 'VEHICLETYREWIDTHAXLE1FRONT',
        VehicleTyreWidthAxle2Front: 'VEHICLETYREWIDTHAXLE2FRONT',
        VehicleTyreWidthAxle1Rear: 'VEHICLETYREWIDTHAXLE1REAR',
        VehicleTyreWidthAxle2Rear: 'VEHICLETYREWIDTHAXLE2REAR',
        VehicleTyreWidthAxle3Rear: 'VEHICLETYREWIDTHAXLE3REAR',
        VehicleFrontOverhang: 'VEHICLEFRONTOVERHANG',
        VehicleFrontOverhangMax: 'VEHICLEFRONTOVERHANGMAX',
        VehicleAxle1FrontToAxle2Front: 'VEHICLEAXLE1FRONTTOAXLE2FRONT',
        VehicleRearMostAxleFrontToAxle1Rear: 'VEHICLEREARMOSTAXLEFRONTTOAXLE1REAR',
        VehicleAxle1RearToAxle2Rear: 'VEHICLEAXLE1REARTOAXLE2REAR',
        VehicleAxle2RearToAxle3Rear: 'VEHICLEAXLE2REARTOAXLE3REAR',
        VehicleAxle3RearToAxle4Rear: 'VEHICLEAXLE3REARTOAXLE4REAR',
        VehicleAxle1FrontToWheelbaseTheoreticalStart: 'VEHICLEAXLE1FRONTTOWHEELBASETHEORETICALSTART',
        VehicleWheelbaseTheoretical: 'VEHICLEWHEELBASETHEORETICAL',
        VehicleMaxWheelbaseCut: 'VEHICLEMAXWHEELBASECUT',
        VehicleWheelbaseChangeIncrement: 'VEHICLEWHEELBASECHANGEINCREMENT',
        VehicleWheelbaseTheoreticalManufacturerMin: 'VEHICLEWHEELBASETHEORETICALMANUFACTURERMIN',
        VehicleWheelbaseTheoreticalManufacturerMax: 'VEHICLEWHEELBASETHEORETICALMANUFACTURERMAX',
        VehicleRearOverhang: 'VEHICLEREAROVERHANG',
        VehicleRearOverhangMin: 'VEHICLEREAROVERHANGMIN',
        VehicleRearProtrusion: 'VEHICLEREARPROTRUSION',
        VehicleRearOverhangTotal: 'VEHICLEREAROVERHANGTOTAL',
        VehicleBumperToBackOfCab: 'VEHICLEBUMPERTOBACKOFCAB',
        VehicleAxleFrontGGA: 'VEHICLEAXLEFRONTGGA',
        VehicleAxleRearGGA: 'VEHICLEAXLEREARGGA',
        VehicleAxleFrontAAU: 'VEHICLEAXLEFRONTAAU',
        VehicleAxleRearAAU: 'VEHICLEAXLEREARAAU',
        VehiclePermissibleFront: 'VEHICLEPERMISSIBLEFRONT',
        VehiclePermissibleRear: 'VEHICLEPERMISSIBLEREAR',
        VehicleGVM: 'VEHICLEGVM',
        VehicleGCM: 'VEHICLEGCM',
        VehicleV: 'VEHICLEV',
        VehicleDT: 'VEHICLEDT',
        VehicleFrontRoadFriendlySuspension: 'VEHICLEFRONTROADFRIENDLYSUSPENSION',
        VehicleRearRoadFriendlySuspension: 'VEHICLEREARROADFRIENDLYSUSPENSION',
        VehicleAxlePositionRearNoOfTyres: 'VEHICLEAXLEPOSITIONREARNOOFTYRES',
        VehicleRolloverProtection: 'VEHICLEROLLOVERPROTECTION',
        VehicleSteeringAngle: 'VEHICLESTEERINGANGLE',
        VehicleTurningRadiusWallToWall: 'VEHICLETURNINGRADIUSWALLTOWALL',
        VehicleTurningRadiusCurbToCurb: 'VEHICLETURNINGRADIUSCURBTOCURB',
        VehicleFrontUnderrunProtection: 'VEHICLEFRONTUNDERRUNPROTECTION',
        VehicleAxle1Position: 'VEHICLEAXLE1POSITION',
        VehicleAxle2Position: 'VEHICLEAXLE2POSITION',
        VehicleAxle3Position: 'VEHICLEAXLE3POSITION',
        VehicleAxle4Position: 'VEHICLEAXLE4POSITION',
        VehicleAxle1Lifting: 'VEHICLEAXLE1LIFTING',
        VehicleAxle2Lifting: 'VEHICLEAXLE2LIFTING',
        VehicleAxle3Lifting: 'VEHICLEAXLE3LIFTING',
        VehicleAxle4Lifting: 'VEHICLEAXLE4LIFTING',
        VehicleAxle1FrontLifting: 'VEHICLEAXLE1FRONTLIFTING',
        VehicleAxle2FrontLifting: 'VEHICLEAXLE2FRONTLIFTING',
        VehicleAxle1RearLifting: 'VEHICLEAXLE1REARLIFTING',
        VehicleAxle2RearLifting: 'VEHICLEAXLE2REARLIFTING',
        VehicleAxle3RearLifting: 'VEHICLEAXLE3REARLIFTING',
        VehicleAxle4RearLifting: 'VEHICLEAXLE4REARLIFTING',
        VehicleAxle1FrontSteering: 'VEHICLEAXLE1FRONTSTEERING',
        VehicleAxle2FrontSteering: 'VEHICLEAXLE2FRONTSTEERING',
        VehicleAxle1RearSteering: 'VEHICLEAXLE1REARSTEERING',
        VehicleAxle2RearSteering: 'VEHICLEAXLE2REARSTEERING',
        VehicleAxle3RearSteering: 'VEHICLEAXLE3REARSTEERING',
        VehicleAxle4RearSteering: 'VEHICLEAXLE4REARSTEERING',
        VehicleAxle1FrontRaised: 'VEHICLEAXLE1FRONTRAISED',
        VehicleAxle2FrontRaised: 'VEHICLEAXLE2FRONTRAISED',
        VehicleAxle1RearRaised: 'VEHICLEAXLE1REARRAISED',
        VehicleAxle2RearRaised: 'VEHICLEAXLE2REARRAISED',
        VehicleAxle3RearRaised: 'VEHICLEAXLE3REARRAISED',
        VehicleAxle4RearRaised: 'VEHICLEAXLE4REARRAISED',
        VehicleAxle1Steering: 'VEHICLEAXLE1STEERING',
        VehicleAxle2Steering: 'VEHICLEAXLE2STEERING',
        VehicleAxle3Steering: 'VEHICLEAXLE3STEERING',
        VehicleAxle4Steering: 'VEHICLEAXLE4STEERING',
        VehicleRearOverhangManufacturerMin: 'VEHICLEREAROVERHANGMANUFACTURERMIN',
        VehicleBreakPointForRearOverhang: 'VEHICLEBREAKPOINTFORREAROVERHANG',
        VehicleAxle1RearManufacturerRating: 'VEHICLEAXLE1REARMANUFACTURERRATING',
        VehicleAxle2RearManufacturerRating: 'VEHICLEAXLE2REARMANUFACTURERRATING',
        VehicleAxle3RearManufacturerRating: 'VEHICLEAXLE3REARMANUFACTURERRATING',
        VehicleAxle4RearManufacturerRating: 'VEHICLEAXLE4REARMANUFACTURERRATING',
        VehicleNoOfAxlesRearRear: 'VEHICLENOOFAXLESREARREAR',
        VehicleSumOfManufacturerRatingRearRear: 'VEHICLESUMOFMANUFACTURERRATINGREARREAR',
        VehicleOverallWidthMax:'VEHICLEOVERALLWIDTHMAX',

        Trailer1DrawbarType: 'TRAILER1DRAWBARTYPE',
        Trailer1Type: 'TRAILER1TYPE',
        Trailer1GVM: 'TRAILER1GVM',
        Trailer1WheelbaseTheoretical: 'TRAILER1WHEELBASETHEORETICAL',
        Trailer1Axle2FrontToAxle3Front: 'TRAILER1AXLE2FRONTTOAXLE3FRONT',
        Trailer1Axle1FrontToAxle2Front: 'TRAILER1AXLE1FRONTTOAXLE2FRONT',
        Trailer1RearMostFrontAxleToAxle1Rear: 'TRAILER1REARMOSTFRONTAXLETOAXLE1REAR',
        Trailer1Axle1RearToAxle2Rear: 'TRAILER1AXLE1REARTOAXLE2REAR',
        Trailer1Axle2RearToAxle3Rear: 'TRAILER1AXLE2REARTOAXLE3REAR',
        Trailer1Axle3RearToAxle4Rear: 'TRAILER1AXLE3REARTOAXLE4REAR',
        Trailer1NoOfTyresAxle1Front: 'TRAILER1NOOFTYRESAXLE1FRONT',
        Trailer1NoOfTyresAxle2Front: 'TRAILER1NOOFTYRESAXLE2FRONT',
        Trailer1NoOfTyresAxle3Front: 'TRAILER1NOOFTYRESAXLE3FRONT',
        Trailer1NoOfTyresAxle1Rear: 'TRAILER1NOOFTYRESAXLE1REAR',
        Trailer1NoOfTyresAxle2Rear: 'TRAILER1NOOFTYRESAXLE2REAR',
        Trailer1NoOfTyresAxle3Rear: 'TRAILER1NOOFTYRESAXLE3REAR',
        Trailer1NoOfTyresAxle4Rear: 'TRAILER1NOOFTYRESAXLE4REAR',
        Trailer1OverallWidth: 'TRAILER1OVERALLWIDTH',
        Trailer1DrawbarLength: 'TRAILER1DRAWBARLENGTH',
        Trailer1WheelbaseTheoreticalEndToAxle4Rear: 'TRAILER1WHEELBASETHEORETICALENDTOAXLE4REAR',
        Trailer1ManufacturersRearAxleMassGAGAURear: 'TRAILER1MANUFACTURERSREARAXLEMASSGAGAUREAR',
        Trailer1ManufacturersFrontAxleMassGAGAUFront: 'TRAILER1MANUFACTURERSFRONTAXLEMASSGAGAUFRONT',
        Trailer1FrontRoadFriendlySuspension: 'TRAILER1FRONTROADFRIENDLYSUSPENSION',
        Trailer1RearRoadFriendlySuspension: 'TRAILER1REARROADFRIENDLYSUSPENSION',
        Trailer1FrontOverhang: 'TRAILER1FRONTOVERHANG',
        Trailer1WheelbaseTheoreticalStartFromAxle1Front: 'TRAILER1WHEELBASETHEORETICALSTARTFROMAXLE1FRONT',
        Trailer1TyreWidth: 'TRAILER1TYREWIDTH',
        Trailer1RearOverhangTotal: 'TRAILER1REAROVERHANGTOTAL',
        Trailer1RearOverhangLegislation: 'TRAILER1REAROVERHANGLEGISLATION',
        Trailer1RearOverhangLegislationMax: 'TRAILER1REAROVERHANGLEGISLATIONMAX',
        Trailer1MaxDrawbarLengthCut: 'TRAILER1MAXDRAWBARLENGTHCUT',
        Trailer1MaxFrontOverhangCut: 'TRAILER1MAXFRONTOVERHANGCUT',
        Trailer1MaxWheelbaseTheoreticalCut: 'TRAILER1MAXWHEELBASETHEORETICALCUT',
        Trailer1MaxTrailerLengthCut: 'TRAILER1MAXTRAILERLENGTHCUT',
        Trailer1MaxOverallHeightCut: 'TRAILER1MAXOVERALLHEIGHTCUT',
        Trailer1DrawbarLengthChangeIncrement: 'TRAILER1DRAWBARLENGTHCHANGEINCREMENT',
        Trailer1FrontOverhangChangeIncrement: 'TRAILER1FRONTOVERHANGCHANGEINCREMENT',
        Trailer1WheelbaseChangeIncrement: 'TRAILER1WHEELBASECHANGEINCREMENT',
        Trailer1HeightChangeIncrement: 'TRAILER1HEIGHTCHANGEINCREMENT',
        Trailer1LengthChangeIncrement: 'TRAILER1LENGTHCHANGEINCREMENT',
        Trailer1Status: 'TRAILER1STATUS',
        Trailer1OverallHeight: 'TRAILER1OVERALLHEIGHT',
        Trailer1AxleLastToChassisEnd: 'TRAILER1AXLELASTTOCHASSISEND',
        Trailer1InternalBodyLength: 'TRAILER1INTERNALBODYLENGTH',
        Trailer1TyreRadius: 'TRAILER1TYRERADIUS',
        Trailer1PermissibleRear: 'TRAILER1PERMISSIBLEREAR',
        Trailer1WheelbaseLegislation: 'TRAILER1WHEELBASELEGISLATION',

        Trailer2DrawbarType: 'TRAILER2DRAWBARTYPE',
        Trailer2Type: 'TRAILER2TYPE',
        Trailer2GVM: 'TRAILER2GVM',
        Trailer2WheelbaseTheoretical: 'TRAILER2WHEELBASETHEORETICAL',
        Trailer2Axle2FrontToAxle3Front: 'TRAILER2AXLE2FRONTTOAXLE3FRONT',
        Trailer2Axle1FrontToAxle2Front: 'TRAILER2AXLE1FRONTTOAXLE2FRONT',
        Trailer2RearMostFrontAxleToAxle1Rear: 'TRAILER2REARMOSTFRONTAXLETOAXLE1REAR',
        Trailer2Axle1RearToAxle2Rear: 'TRAILER2AXLE1REARTOAXLE2REAR',
        Trailer2Axle2RearToAxle3Rear: 'TRAILER2AXLE2REARTOAXLE3REAR',
        Trailer2Axle3RearToAxle4Rear: 'TRAILER2AXLE3REARTOAXLE4REAR',
        Trailer2NoOfTyresAxle1Front: 'TRAILER2NOOFTYRESAXLE1FRONT',
        Trailer2NoOfTyresAxle2Front: 'TRAILER2NOOFTYRESAXLE2FRONT',
        Trailer2NoOfTyresAxle3Front: 'TRAILER2NOOFTYRESAXLE3FRONT',
        Trailer2NoOfTyresAxle1Rear: 'TRAILER2NOOFTYRESAXLE1REAR',
        Trailer2NoOfTyresAxle2Rear: 'TRAILER2NOOFTYRESAXLE2REAR',
        Trailer2NoOfTyresAxle3Rear: 'TRAILER2NOOFTYRESAXLE3REAR',
        Trailer2NoOfTyresAxle4Rear: 'TRAILER2NOOFTYRESAXLE4REAR',
        Trailer2OverallWidth: 'TRAILER2OVERALLWIDTH',
        Trailer2DrawbarLength: 'TRAILER2DRAWBARLENGTH',
        Trailer2WheelbaseTheoreticalEndToAxle4Rear: 'TRAILER2WHEELBASETHEORETICALENDTOAXLE4REAR',
        Trailer2ManufacturersRearAxleMassGAGAURear: 'TRAILER2MANUFACTURERSREARAXLEMASSGAGAUREAR',
        Trailer2ManufacturersFrontAxleMassGAGAUFront: 'TRAILER2MANUFACTURERSFRONTAXLEMASSGAGAUFRONT',
        Trailer2FrontRoadFriendlySuspension: 'TRAILER2FRONTROADFRIENDLYSUSPENSION',
        Trailer2RearRoadFriendlySuspension: 'TRAILER2REARROADFRIENDLYSUSPENSION',
        Trailer2FrontOverhang: 'TRAILER2FRONTOVERHANG',
        Trailer2WheelbaseTheoreticalStartFromAxle1Front: 'TRAILER2WHEELBASETHEORETICALSTARTFROMAXLE1FRONT',
        Trailer2TyreWidth: 'TRAILER2TYREWIDTH',
        Trailer2RearOverhangTotal: 'TRAILER2REAROVERHANGTOTAL',
        Trailer2RearOverhangLegislation: 'TRAILER2REAROVERHANGLEGISLATION',
        Trailer2RearOverhangLegislationMax: 'TRAILER2REAROVERHANGLEGISLATIONMAX',
        Trailer2MaxDrawbarLengthCut: 'TRAILER2MAXDRAWBARLENGTHCUT',
        Trailer2MaxFrontOverhangCut: 'TRAILER2MAXFRONTOVERHANGCUT',
        Trailer2MaxWheelbaseTheoreticalCut: 'TRAILER2MAXWHEELBASETHEORETICALCUT',
        Trailer2MaxTrailerLengthCut: 'TRAILER2MAXTRAILERLENGTHCUT',
        Trailer2MaxOverallHeightCut: 'TRAILER2MAXOVERALLHEIGHTCUT',
        Trailer2DrawbarLengthChangeIncrement: 'TRAILER2DRAWBARLENGTHCHANGEINCREMENT',
        Trailer2FrontOverhangChangeIncrement: 'TRAILER2FRONTOVERHANGCHANGEINCREMENT',
        Trailer2WheelbaseChangeIncrement: 'TRAILER2WHEELBASECHANGEINCREMENT',
        Trailer2HeightChangeIncrement: 'TRAILER2HEIGHTCHANGEINCREMENT',
        Trailer2LengthChangeIncrement: 'TRAILER2LENGTHCHANGEINCREMENT',
        Trailer2Status: 'TRAILER2STATUS',
        Trailer2OverallHeight: 'TRAILER2OVERALLHEIGHT',
        Trailer2AxleLastToChassisEnd: 'TRAILER2AXLELASTTOCHASSISEND',
        Trailer2InternalBodyLength: 'TRAILER2INTERNALBODYLENGTH',
        Trailer2TyreRadius: 'TRAILER2TYRERADIUS',
        Trailer2PermissibleRear: 'TRAILER2PERMISSIBLEREAR',
        Trailer2WheelbaseLegislation: 'TRAILER2WHEELBASELEGISLATION',

        BullbarProtrusion: 'BULLBARPROTRUSION',
        BullbarAdded: 'BULLBARADDED',

        RolloverAdded: 'ROLLOVERADDED',

        CombinationType: 'COMBINATIONTYPE',
        CombinationOverallLength: 'COMBINATIONOVERALLLENGTH',
        VehicleFrontGross: 'VEHICLEFRONTGROSS',
        VehicleRearGross: 'VEHICLEREARGROSS',
        Trailer1FrontGross: 'TRAILER1FRONTGROSS',
        Trailer1RearGross: 'TRAILER1REARGROSS',
        Trailer2FrontGross: 'TRAILER2FRONTGROSS',
        Trailer2RearGross: 'TRAILER2REARGROSS',

        ComVehAxle3RearToRearMostAccessory: 'COMVEHAXLE3REARTOREARMOSTACCESSORY',
        ComVehFrontGross: 'COMVEHFRONTGROSS',
        ComVehRearGross: 'COMVEHREARGROSS',
        ComVehTotalGross: 'COMVEHTOTALGROSS',
        ComVehRearOverhangTotal: 'COMVEHREAROVERHANGTOTAL',
        ComVehOverallLength: 'COMVEHOVERALLLENGTH',
        ComVehGroup1DistanceRounded: 'COMVEHGROUP1DISTANCEROUNDED',

        ComVehDrawbarTotalGross: 'COMVEHDRAWBARTOTALGROSS',
        ComVehDrawbarVehRearGross: 'COMVEHDRAWBARVEHREARGROSS',
        ComVehDrawbarGroup1DistanceRounded: 'COMVEHDRAWBARGROUP1DISTANCEROUNDED',
        ComVehDrawbarGroup2DistanceRounded: 'COMVEHDRAWBARGROUP2DISTANCEROUNDED',
        ComVehDrawbarGroup3DistanceRounded: 'COMVEHDRAWBARGROUP3DISTANCEROUNDED',
        ComVehDrawbarGroup4DistanceRounded: 'COMVEHDRAWBARGROUP4DISTANCEROUNDED',
        ComVehDrawbarGroup5DistanceRounded: 'COMVEHDRAWBARGROUP5DISTANCEROUNDED',
        ComVehDrawbarGroup6DistanceRounded: 'COMVEHDRAWBARGROUP6DISTANCEROUNDED',

        ComVehPupTotalGross: 'COMVEHPUPTOTALGROSS',
        ComVehPupVehRearGross: 'COMVEHPUPVEHREARGROSS',
        ComVehPupGroup1DistanceRounded: 'COMVEHPUPGROUP1DISTANCEROUNDED',
        ComVehPupGroup2DistanceRounded: 'COMVEHPUPGROUP2DISTANCEROUNDED',
        ComVehPupGroup3DistanceRounded: 'COMVEHPUPGROUP3DISTANCEROUNDED',
        ComVehPupTrailerRearOverhangTotal: 'COMVEHPUPTRAILERREAROVERHANGTOTAL',

        ComVehSemiTotalGross: 'COMVEHSEMITOTALGROSS',
        ComVehSemiVehRearGross: 'COMVEHSEMIVEHREARGROSS',
        ComVehSemiGroup1DistanceRounded: 'COMVEHSEMIGROUP1DISTANCEROUNDED',
        ComVehSemiGroup2DistanceRounded: 'COMVEHSEMIGROUP2DISTANCEROUNDED',
        ComVehSemiGroup3DistanceRounded: 'COMVEHSEMIGROUP3DISTANCEROUNDED',

        ComVehIntSemiTotalGross: 'COMVEHINTSEMITOTALGROSS',
        ComVehIntSemiVehRearGross: 'COMVEHINTSEMIVEHREARGROSS',
        ComVehIntSemiGroup1DistanceRounded: 'COMVEHINTSEMIGROUP1DISTANCEROUNDED',
        ComVehIntSemiGroup2DistanceRounded: 'COMVEHINTSEMIGROUP2DISTANCEROUNDED',
        ComVehIntSemiGroup3DistanceRounded: 'COMVEHINTSEMIGROUP3DISTANCEROUNDED',
        ComVehIntSemiGroup4DistanceRounded: 'COMVEHINTSEMIGROUP4DISTANCEROUNDED',
        ComVehIntSemiGroup5DistanceRounded: 'COMVEHINTSEMIGROUP5DISTANCEROUNDED',
        ComVehIntSemiGroup6DistanceRounded: 'COMVEHINTSEMIGROUP6DISTANCEROUNDED',
        ComVehIntSemiXDimension: 'COMVEHINTSEMIXDIMENSION',
        ComVehIntSemiYDimension: 'COMVEHINTSEMIYDIMENSION',

        ComVehSemiDrawbarTotalGross: 'COMVEHSEMIDRAWBARTOTALGROSS',
        ComVehSemiDrawbarVehRearGross: 'COMVEHSEMIDRAWBARVEHREARGROSS',
        ComVehSemiDrawbarGroup1DistanceRounded: 'COMVEHSEMIDRAWBARGROUP1DISTANCEROUNDED',
        ComVehSemiDrawbarGroup2DistanceRounded: 'COMVEHSEMIDRAWBARGROUP2DISTANCEROUNDED',
        ComVehSemiDrawbarGroup3DistanceRounded: 'COMVEHSEMIDRAWBARGROUP3DISTANCEROUNDED',
        ComVehSemiDrawbarGroup4DistanceRounded: 'COMVEHSEMIDRAWBARGROUP4DISTANCEROUNDED',
        ComVehSemiDrawbarGroup5DistanceRounded: 'COMVEHSEMIDRAWBARGROUP5DISTANCEROUNDED',
        ComVehSemiDrawbarGroup6DistanceRounded: 'COMVEHSEMIDRAWBARGROUP6DISTANCEROUNDED',
        ComVehSemiDrawbarGroup7DistanceRounded: 'COMVEHSEMIDRAWBARGROUP7DISTANCEROUNDED',
        ComVehSemiDrawbarGroup8DistanceRounded: 'COMVEHSEMIDRAWBARGROUP8DISTANCEROUNDED',
        ComVehSemiDrawbarGroup9DistanceRounded: 'COMVEHSEMIDRAWBARGROUP9DISTANCEROUNDED',
        ComVehSemiDrawbarGroup10DistanceRounded: 'COMVEHSEMIDRAWBARGROUP10DISTANCEROUNDED',
        ComVehSemiDrawbarOverallLength: 'COMVEHSEMIDRAWBAROVERALLLENGTH',

        ComVehSemiPupTotalGross: 'COMVEHSEMIPUPTOTALGROSS',
        ComVehSemiPupVehRearGross: 'COMVEHSEMIPUPVEHREARGROSS',
        ComVehSemiPupGroup1DistanceRounded: 'COMVEHSEMIPUPGROUP1DISTANCEROUNDED',
        ComVehSemiPupGroup2DistanceRounded: 'COMVEHSEMIPUPGROUP2DISTANCEROUNDED',
        ComVehSemiPupGroup3DistanceRounded: 'COMVEHSEMIPUPGROUP3DISTANCEROUNDED',
        ComVehSemiPupGroup4DistanceRounded: 'COMVEHSEMIPUPGROUP4DISTANCEROUNDED',
        ComVehSemiPupGroup5DistanceRounded: 'COMVEHSEMIPUPGROUP5DISTANCEROUNDED',
        ComVehSemiPupGroup6DistanceRounded: 'COMVEHSEMIPUPGROUP6DISTANCEROUNDED',
        ComVehSemiPupTrailerRearOverhangTotal: 'COMVEHSEMIPUPTRAILERREAROVERHANGTOTAL',
        //ComVehSemiPupOverallLength: 'COMVEHSEMIPUPOVERALLLENGTH',

        ComVehSemiPermissibleTotalSub: 'COMVEHSEMIPERMISSIBLETOTALSUB',

        BodyTypeVehicle: 'BODYTYPEVEHICLE',
        BodyGapVehicle: 'BODYGAPVEHICLE',
        BodyLengthTrailer1: 'BODYLENGTHTRAILER1',
        BodyGapTrailer1: 'BODYGAPTRAILER1',
        BodyLengthTrailer2: 'BODYLENGTHTRAILER2',
        BodyGapTrailer2: 'BODYGAPTRAILER2',
        BodyWidthManufacturerMax: 'BODYWIDTHMANUFACTURERMAX',

        //new variables for evaluation
        ComVehAxle1FrontToWheelbaseLegislationStart: 'COMVEHAXLE1FRONTTOWHEELBASELEGISLATIONSTART',
        ComVehWheelbaseLegislation: 'COMVEHWHEELBASELEGISLATION',
        ComVehRearOverhangLegislation: 'COMVEHREAROVERHANGLEGISLATION',
        ComVehRearOverhangLegislationMax: 'COMVEHREAROVERHANGLEGISLATIONMAX',

        BridgeDistanceRounded: 'BRIDGEDISTANCEROUNDED',
        AxleTolerance: 'AXLETOLERANCE',
        TotalTolerance: 'TOTALTOLERANCE',
        MinimumOnSteeringAxleOfArticulatedPercent: 'MINIMUMONSTEERINGAXLEOFARTICULATEDPERCENT',
        ComVehSteerAxleMinimumApplies: 'COMVEHSTEERAXLEMINIMUMAPPLIES',
        KingpinToRearOfTrailerUsed: 'KINGPINTOREAROFTRAILERUSED',
        BullbarIncludedInLength: 'BULLBARINCLUDEDINLENGTH',
        Trailer1LengthMin: 'TRAILER1LENGTHMIN',
        Trailer1LengthMax: 'TRAILER1LENGTHMAX',
        Trailer2LengthMin: 'TRAILER2LENGTHMIN',
        Trailer2LengthMax: 'TRAILER2LENGTHMAX',
        OverallVehicleLengthMax: 'OVERALLVEHICLELENGTHMAX',
        OverallVehicleHeightMax: 'OVERALLVEHICLEHEIGHTMAX',
        OverallTrailer1HeightMax: 'OVERALLTRAILER1HEIGHTMAX',
        OverallTrailer2HeightMax: 'OVERALLTRAILER2HEIGHTMAX',
        OverallCombinationLengthMax: 'OVERALLCOMBINATIONLENGTHMAX',
        ComVehSemiSteerAxleMinimumApplies: 'COMVEHSEMISTEERAXLEMINIMUMAPPLIES',
        ComVehSemiOverallLengthMax: 'COMVEHSEMIOVERALLLENGTHMAX',
        SemiArticulationToRearOfTrailer: 'SEMIARTICULATIONTOREAROFTRAILER',

        //2018.1
        TurningRadiusCurbToCurbMax: 'TURNINGRADIUSCURBTOCURBMAX',
        TurningRadiusOuterMax: 'TURNINGRADIUSOUTERMAX',
        TurningRadiusInnerMin: 'TURNINGRADIUSINNERMIN',

        //2018.2
        VehicleWheelbaseTheoreticalAdminMax: 'VEHICLEWHEELBASETHEORETICALADMINMAX',
        VehicleWheelbaseTheoreticalMin: 'VEHICLEWHEELBASETHEORETICALMIN',
        Trailer1FrontOverhangMin: 'TRAILER1FRONTOVERHANGMIN',
        Trailer1FrontOverhangMax: 'TRAILER1FRONTOVERHANGMAX',
        Trailer2FrontOverhangMin: 'TRAILER2FRONTOVERHANGMIN',
        Trailer2FrontOverhangMax: 'TRAILER2FRONTOVERHANGMAX',
        Trailer1WheelbaseTheoreticalMin: 'TRAILER1WHEELBASETHEORETICALMIN',
        Trailer1WheelbaseTheoreticalMax: 'TRAILER1WHEELBASETHEORETICALMAX',
        Trailer2WheelbaseTheoreticalMin: 'TRAILER2WHEELBASETHEORETICALMIN',
        Trailer2WheelbaseTheoreticalMax: 'TRAILER2WHEELBASETHEORETICALMAX',

        //2018.3
        ComVehSemiFridgeAdded: 'COMVEHSEMIFRIDGEADDED',
        BodyTypeTrailer1: 'BODYTYPETRAILER1',

        //2018.4
        Trailer1DrawbarProtrusionMin: 'TRAILER1DRAWBARPROTRUSIONMIN',
        Trailer2DrawbarProtrusionMin: 'TRAILER2DRAWBARPROTRUSIONMIN',
        Trailer1PermissibleFront: 'TRAILER1PERMISSIBLEFRONT',
        Trailer2PermissibleFront: 'TRAILER2PERMISSIBLEFRONT',
        ComVehDrawbarPermissibleTotalSub: 'COMVEHDRAWBARPERMISSIBLETOTALSUB',
        ComVehDrawbarSumOfAxles: 'COMVEHDRAWBARSUMOFAXLES',
        Trailer1DrawbarLengthLegislation: 'TRAILER1DRAWBARLENGTHLEGISLATION',
        Trailer2DrawbarLengthLegislation: 'TRAILER2DRAWBARLENGTHLEGISLATION',
        Trailer1Axle1FrontToWheelbaseLegislationStart: 'TRAILER1AXLE1FRONTTOWHEELBASELEGISLATIONSTART',
        Trailer2Axle1FrontToWheelbaseLegislationStart: 'TRAILER2AXLE1FRONTTOWHEELBASELEGISLATIONSTART',
        ComVehDrawbarOverallLengthMax: 'COMVEHDRAWBAROVERALLLENGTHMAX',
        ComVehDrawbarDrawbarHeavierThanVehicleApplies: 'COMVEHDRAWBARDRAWBARHEAVIERTHANVEHICLEAPPLIES',
        Trailer1OverallWidthMax: 'TRAILER1OVERALLWIDTHMAX',
        Trailer2OverallWidthMax: 'TRAILER2OVERALLWIDTHMAX',

        //2018.6
        ComVehBridgeFormulaUsed: 'COMVEHBRIDGEFORMULAUSED',
        ComVehGroup1Permissible: 'COMVEHGROUP1PERMISSIBLE',
        ComVehSemiGroup2Permissible: 'COMVEHSEMIGROUP2PERMISSIBLE',
        ComVehDrawbarGroup2Permissible: 'COMVEHDRAWBARGROUP2PERMISSIBLE',
        ComVehSemiGroup3Permissible: 'COMVEHSEMIGROUP3PERMISSIBLE',
        ComVehDrawbarGroup3Permissible: 'COMVEHDRAWBARGROUP3PERMISSIBLE',
        ComVehDrawbarGroup4Permissible: 'COMVEHDRAWBARGROUP4PERMISSIBLE',
        ComVehDrawbarGroup5Permissible: 'COMVEHDRAWBARGROUP5PERMISSIBLE',
        ComVehDrawbarGroup6Permissible: 'COMVEHDRAWBARGROUP6PERMISSIBLE',
        BridgeTolerance: 'BRIDGETOLERANCE',
        ComVehDrawbarBridgeFormulaUsed: 'COMVEHDRAWBARBRIDGEFORMULAUSED',
        ComVehSemiBridgeFormulaUsed: 'COMVEHSEMIBRIDGEFORMULAUSED',
        ComVehSemiGroup1Permissible: 'COMVEHSEMIGROUP1PERMISSIBLE',
        ComVehDrawbarGroup1Permissible: 'COMVEHDRAWBARGROUP1PERMISSIBLE',
        SemiOrDogArticulationToRearOfTrailer: 'SEMIORDOGARTICULATIONTOREAROFTRAILER',
        Trailer1DrawbarLengthMax: 'TRAILER1DRAWBARLENGTHMAX',
        ComVehDrawbarGapBetweenVehicleAndTrailerMax: 'COMVEHDRAWBARGAPBETWEENVEHICLEANDTRAILERMAX',
        VehicleAxle1RearSuspensionRating: 'VEHICLEAXLE1REARSUSPENSIONRATING',
        VehicleAxle2RearSuspensionRating: 'VEHICLEAXLE2REARSUSPENSIONRATING',
        VehicleAxle3RearSuspensionRating: 'VEHICLEAXLE3REARSUSPENSIONRATING',
        VehicleAxle4RearSuspensionRating: 'VEHICLEAXLE4REARSUSPENSIONRATING',
        VehicleAxle1RearTyreRating: 'VEHICLEAXLE1REARTYRERATING',
        VehicleAxle2RearTyreRating: 'VEHICLEAXLE2REARTYRERATING',
        VehicleAxle3RearTyreRating: 'VEHICLEAXLE3REARTYRERATING',
        VehicleAxle4RearTyreRating: 'VEHICLEAXLE4REARTYRERATING',
        VehicleAxle1FrontManufacturerRating: 'VEHICLEAXLE1FRONTMANUFACTURERRATING',
        VehicleAxle2FrontManufacturerRating: 'VEHICLEAXLE2FRONTMANUFACTURERRATING',
        VehicleAxle1FrontSuspensionRating: 'VEHICLEAXLE1FRONTSUSPENSIONRATING',
        VehicleAxle2FrontSuspensionRating: 'VEHICLEAXLE2FRONTSUSPENSIONRATING',
        VehicleAxle1FrontTyreRating: 'VEHICLEAXLE1FRONTTYRERATING',
        VehicleAxle2FrontTyreRating: 'VEHICLEAXLE2FRONTTYRERATING',

        //2018.8
        ComVehPupBridgeFormulaUsed: 'COMVEHPUPBRIDGEFORMULAUSED',
        ComVehPupSumOfAxles: 'COMVEHPUPSUMOFAXLES',
        ComVehPupPermissibleTotalSub: 'COMVEHPUPPERMISSIBLETOTALSUB',
        ComVehPupGroup1Permissible: 'COMVEHPUPGROUP1PERMISSIBLE',
        ComVehPupGroup2Permissible: 'COMVEHPUPGROUP2PERMISSIBLE',
        ComVehPupGroup3Permissible: 'COMVEHPUPGROUP3PERMISSIBLE',
        ComVehSteerAxleDualUnder: 'COMVEHSTEERAXLEDUALUNDER',
        ComVehSteerAxleSingleUnder: 'COMVEHSTEERAXLESINGLEUNDER',
        MinimumOnDualSteeringAxlePercent: 'MINIMUMONDUALSTEERINGAXLEPERCENT',
        MinimumOnSingleSteeringAxlePercent: 'MINIMUMONSINGLESTEERINGAXLEPERCENT',
        ComVehPupPupHeavierThanVehicleApplies: 'COMVEHPUPPUPHEAVIERTHANVEHICLEAPPLIES',
        ComVehPupOverallLengthMax: 'COMVEHPUPOVERALLLENGTHMAX',
        ComVehPupGapBetweenVehicleAndTrailerMax: 'COMVEHPUPGAPBETWEENVEHICLEANDTRAILERMAX',

        //2018.9
        VehicleTypeDistributionCode: 'VEHICLETYPEDISTRIBUTIONCODE',
        Trailer1FridgeAdded: 'TRAILER1FRIDGEADDED',
        Trailer2FridgeAdded: 'TRAILER2FRIDGEADDED',

        //2018.10
        VehicleWheelbaseTheoreticalMax: 'VEHICLEWHEELBASETHEORETICALMAX',

        //2019.1
        BridgeComplianceOnly: 'BRIDGECOMPLIANCEONLY',
        VehicleFridgeAdded: 'VEHICLEFRIDGEADDED',
        VehicleFrontSingleOrDual: 'VEHICLEFRONTSINGLEORDUAL',
        VehicleRearSingleDualTridem: 'VEHICLEREARSINGLEDUALTRIDEM',
        PermissibleMaximumSingleAxleMassSteeringSingles: 'PERMISSIBLEMAXIMUMSINGLEAXLEMASSSTEERINGSINGLES',
        PermissibleMaximumSingleAxleMassSingles: 'PERMISSIBLEMAXIMUMSINGLEAXLEMASSSINGLES',
        PermissibleMaximumSingleAxleMassDuals: "PERMISSIBLEMAXIMUMSINGLEAXLEMASSDUALS",
        Trailer1PermissibleRearMax: 'TRAILER1PERMISSIBLEREARMAX',
        Trailer1PermissibleFrontMax: 'TRAILER1PERMISSIBLEFRONTMAX',
        VehicleWheelbaseSpecified: 'VEHICLEWHEELBASESPECIFIED',

        //2019.3
        ComVehIntSemiSumOfAxles: 'COMVEHINTSEMISUMOFAXLES',
        ComVehIntSemiPermissibleTotalSub: 'COMVEHINTSEMIPERMISSIBLETOTALSUB',
        Trailer2Allowed: 'TRAILER2ALLOWED',
        ComVehIntSemiBridgeFormulaUsed: 'COMVEHINTSEMIBRIDGEFORMULAUSED',
        ComVehIntSemiGroup1Permissible: 'COMVEHINTSEMIGROUP1PERMISSIBLE',
        ComVehIntSemiGroup2Permissible: 'COMVEHINTSEMIGROUP2PERMISSIBLE',
        ComVehIntSemiGroup3Permissible: 'COMVEHINTSEMIGROUP3PERMISSIBLE',
        ComVehIntSemiGroup4Permissible: 'COMVEHINTSEMIGROUP4PERMISSIBLE',
        ComVehIntSemiGroup5Permissible: 'COMVEHINTSEMIGROUP5PERMISSIBLE',
        ComVehIntSemiGroup6Permissible: 'COMVEHINTSEMIGROUP6PERMISSIBLE',
        ComVehIntSemiXYUsed: 'COMVEHINTSEMIXYUSED',
        ComVehIntSemiSteerAxleMinimumApplies: 'COMVEHINTSEMISTEERAXLEMINIMUMAPPLIES',
        ComVehIntSemiOverallLengthMax: 'COMVEHINTSEMIOVERALLLENGTHMAX',
        ComVehIntSemiXExceeded: 'COMVEHINTSEMIXEXCEEDED',
        ComVehIntSemiYExceeded: 'COMVEHINTSEMIYEXCEEDED',
        ComVehIntSemiXMinusYMax: 'COMVEHINTSEMIXMINUSYMAX',
        ComVehIntSemiYMinusXMax: 'COMVEHINTSEMIYMINUSXMAX',
        AbnormalPermit: 'ABNORMALPERMIT',
        Trailer1ArticulationPointToRearOfBDouble: 'TRAILER1ARTICULATIONPOINTTOREAROFBDOUBLE',
        Trailer1LengthMaxAdmin: 'TRAILER1LENGTHMAXADMIN',
        Trailer2LengthMaxAdmin: 'TRAILER2LENGTHMAXADMIN',
        ShowHigherMassLimitsOption: 'SHOWHIGHERMASSLIMITSOPTION',

        //2019.4
        VehiclePermissibleRearPusher: 'VEHICLEPERMISSIBLEREARPUSHER',
        VehiclePermissibleRearTag: 'VEHICLEPERMISSIBLEREARTAG',
        VehiclePermissibleRearRearOnly: 'VEHICLEPERMISSIBLEREARREARONLY',
        ShowSuspension: 'SHOWSUSPENSION',

        //2019.5
        Trailer2PermissibleRearMax: 'TRAILER2PERMISSIBLEREARMAX',

        //2019.6
        ComVehBodyLengthMax: 'COMVEHBODYLENGTHMAX',
        VehicleWheelbaseTheoreticalAdminMin: 'VEHICLEWHEELBASETHEORETICALADMINMIN',
        ShowWheelbaseMinimum: 'SHOWWHEELBASEMINIMUM',
        ShowWheelbaseManufacturerMinimum: 'SHOWWHEELBASEMANUFACTURERMINIMUM',
        ShowWheelbaseMaximum: 'SHOWWHEELBASEMAXIMUM',
        ShowWheelbaseManufacturerMaximum: 'SHOWWHEELBASEMANUFACTURERMAXIMUM',
        ShowBodyLengthMaximum: 'SHOWBODYLENGTHMAXIMUM',

        //2020.1
        BridgeMethodTwoOrMoreConsecutiveAxles: 'BRIDGEMETHODTWOORMORECONSECUTIVEAXLES',
        ComVehGroup2Permissible: 'COMVEHGROUP2PERMISSIBLE',
        ComVehGroup3Permissible: 'COMVEHGROUP3PERMISSIBLE',
        ComVehGroup4Permissible: 'COMVEHGROUP4PERMISSIBLE',
        ComVehGroup5Permissible: 'COMVEHGROUP5PERMISSIBLE',
        ComVehGroup6Permissible: 'COMVEHGROUP6PERMISSIBLE',
        ComVehGroup7Permissible: 'COMVEHGROUP7PERMISSIBLE',
        ComVehGroup8Permissible: 'COMVEHGROUP8PERMISSIBLE',
        ComVehGroup9Permissible: 'COMVEHGROUP9PERMISSIBLE',
        ComVehGroup10Permissible: 'COMVEHGROUP10PERMISSIBLE',
        ComVehGroup1NoOfAxles: 'COMVEHGROUP1NOOFAXLES',
        ComVehGroup2DistanceRounded: 'COMVEHGROUP2DISTANCEROUNDED',
        ComVehGroup2NoOfAxles: 'COMVEHGROUP2NOOFAXLES',
        ComVehGroup3DistanceRounded: 'COMVEHGROUP3DISTANCEROUNDED',
        ComVehGroup3NoOfAxles: 'COMVEHGROUP3NOOFAXLES',
        ComVehGroup4DistanceRounded: 'COMVEHGROUP4DISTANCEROUNDED',
        ComVehGroup4NoOfAxles: 'COMVEHGROUP4NOOFAXLES',
        PermissibleSingleAxleWeight: 'PERMISSIBLESINGLEAXLEWEIGHT',
        ComVehSemiGroup1NoOfAxles: 'COMVEHSEMIGROUP1NOOFAXLES',
        ComVehSemiGroup2NoOfAxles: 'COMVEHSEMIGROUP2NOOFAXLES',
        ComVehSemiGroup3NoOfAxles: 'COMVEHSEMIGROUP3NOOFAXLES',
        ComVehSemiGroup4DistanceRounded: 'COMVEHSEMIGROUP4DISTANCEROUNDED',
        ComVehSemiGroup4NoOfAxles: 'COMVEHSEMIGROUP4NOOFAXLES',
        ComVehSemiGroup5DistanceRounded: 'COMVEHSEMIGROUP5DISTANCEROUNDED',
        ComVehSemiGroup5NoOfAxles: 'COMVEHSEMIGROUP5NOOFAXLES',
        ComVehSemiGroup6DistanceRounded: 'COMVEHSEMIGROUP6DISTANCEROUNDED',
        ComVehSemiGroup6NoOfAxles: 'COMVEHSEMIGROUP6NOOFAXLES',
        ComVehSemiGroup7DistanceRounded: 'COMVEHSEMIGROUP7DISTANCEROUNDED',
        ComVehSemiGroup7NoOfAxles: 'COMVEHSEMIGROUP7NOOFAXLES',
        ComVehSemiGroup4Permissible: 'COMVEHSEMIGROUP4PERMISSIBLE',
        ComVehSemiGroup5Permissible: 'COMVEHSEMIGROUP5PERMISSIBLE',
        ComVehSemiGroup6Permissible: 'COMVEHSEMIGROUP6PERMISSIBLE',
        ComVehSemiGroup7Permissible: 'COMVEHSEMIGROUP7PERMISSIBLE',
        ComVehPupGroup1NoOfAxles: 'COMVEHPUPGROUP1NOOFAXLES',
        ComVehPupGroup2NoOfAxles: 'COMVEHPUPGROUP2NOOFAXLES',
        ComVehPupGroup3NoOfAxles: 'COMVEHPUPGROUP3NOOFAXLES',
        ComVehPupGroup4DistanceRounded: 'COMVEHPUPGROUP4DISTANCEROUNDED',
        ComVehPupGroup4NoOfAxles: 'COMVEHPUPGROUP4NOOFAXLES',
        ComVehPupGroup5DistanceRounded: 'COMVEHPUPGROUP5DISTANCEROUNDED',
        ComVehPupGroup5NoOfAxles: 'COMVEHPUPGROUP5NOOFAXLES',
        ComVehPupGroup6DistanceRounded: 'COMVEHPUPGROUP6DISTANCEROUNDED',
        ComVehPupGroup6NoOfAxles: 'COMVEHPUPGROUP6NOOFAXLES',
        ComVehPupGroup7DistanceRounded: 'COMVEHPUPGROUP7DISTANCEROUNDED',
        ComVehPupGroup7NoOfAxles: 'COMVEHPUPGROUP7NOOFAXLES',
        ComVehPupGroup4Permissible: 'COMVEHPUPGROUP4PERMISSIBLE',
        ComVehPupGroup5Permissible: 'COMVEHPUPGROUP5PERMISSIBLE',
        ComVehPupGroup6Permissible: 'COMVEHPUPGROUP6PERMISSIBLE',
        ComVehPupGroup7Permissible: 'COMVEHPUPGROUP7PERMISSIBLE',
        VehiclePermissibleRearPusher1: 'VEHICLEPERMISSIBLEREARPUSHER1',
        VehiclePermissibleRearPusher2: 'VEHICLEPERMISSIBLEREARPUSHER2',
        VehiclePermissibleRearPusher3: 'VEHICLEPERMISSIBLEREARPUSHER3',
        VehiclePermissibleRearTag1: 'VEHICLEPERMISSIBLEREARTAG1',
        VehiclePermissibleRearTag2: 'VEHICLEPERMISSIBLEREARTAG2',
        VehiclePermissibleRearTag3: 'VEHICLEPERMISSIBLEREARTAG3',
        ComVehDrawbarGroup1NoOfAxles: 'COMVEHDRAWBARGROUP1NOOFAXLES',
        ComVehDrawbarGroup2NoOfAxles: 'COMVEHDRAWBARGROUP2NOOFAXLES',
        ComVehDrawbarGroup3NoOfAxles: 'COMVEHDRAWBARGROUP3NOOFAXLES',
        ComVehDrawbarGroup4NoOfAxles: 'COMVEHDRAWBARGROUP4NOOFAXLES',
        ComVehDrawbarGroup5NoOfAxles: 'COMVEHDRAWBARGROUP5NOOFAXLES',
        ComVehDrawbarGroup6NoOfAxles: 'COMVEHDRAWBARGROUP6NOOFAXLES',
        ComVehIntSemiGroup1NoOfAxles: 'COMVEHINTSEMIGROUP1NOOFAXLES',
        ComVehIntSemiGroup2NoOfAxles: 'COMVEHINTSEMIGROUP2NOOFAXLES',
        ComVehIntSemiGroup3NoOfAxles: 'COMVEHINTSEMIGROUP3NOOFAXLES',
        ComVehIntSemiGroup4NoOfAxles: 'COMVEHINTSEMIGROUP4NOOFAXLES',
        ComVehIntSemiGroup5NoOfAxles: 'COMVEHINTSEMIGROUP5NOOFAXLES',
        ComVehIntSemiGroup6NoOfAxles: 'COMVEHINTSEMIGROUP6NOOFAXLES',

        //2020.4
        MotorVehicleLengthMaxTowing: 'MOTORVEHICLELENGTHMAXTOWING',
        OverallWheelbaseRestriction: 'OVERALLWHEELBASERESTRICTION',
        OverallWheelbaseMax: 'OVERALLWHEELBASEMAX',

        //2020.5
        HitchAdded: 'HITCHADDED',
        ComVehPermissibleTotalSub: 'COMVEHPERMISSIBLETOTALSUB',

        //2021.1
        ComVehDrawbarGroup7DistanceRounded: 'COMVEHDRAWBARGROUP7DISTANCEROUNDED',
        ComVehDrawbarGroup8DistanceRounded: 'COMVEHDRAWBARGROUP8DISTANCEROUNDED',
        ComVehDrawbarGroup9DistanceRounded: 'COMVEHDRAWBARGROUP9DISTANCEROUNDED',
        ComVehDrawbarGroup10DistanceRounded: 'COMVEHDRAWBARGROUP10DISTANCEROUNDED',
        ComVehDrawbarGroup11DistanceRounded: 'COMVEHDRAWBARGROUP11DISTANCEROUNDED',
        ComVehDrawbarGroup7NoOfAxles: 'COMVEHDRAWBARGROUP7NOOFAXLES',
        ComVehDrawbarGroup8NoOfAxles: 'COMVEHDRAWBARGROUP8NOOFAXLES',
        ComVehDrawbarGroup9NoOfAxles: 'COMVEHDRAWBARGROUP9NOOFAXLES',
        ComVehDrawbarGroup10NoOfAxles: 'COMVEHDRAWBARGROUP10NOOFAXLES',
        ComVehDrawbarGroup11NoOfAxles: 'COMVEHDRAWBARGROUP11NOOFAXLES',
        ComVehDrawbarGroup7Permissible: 'COMVEHDRAWBARGROUP7PERMISSIBLE',
        ComVehDrawbarGroup8Permissible: 'COMVEHDRAWBARGROUP8PERMISSIBLE',
        ComVehDrawbarGroup9Permissible: 'COMVEHDRAWBARGROUP9PERMISSIBLE',
        ComVehDrawbarGroup10Permissible: 'COMVEHDRAWBARGROUP10PERMISSIBLE',
        ComVehDrawbarGroup11Permissible: 'COMVEHDRAWBARGROUP11PERMISSIBLE',


        ComVehIntSemiGroup7DistanceRounded: 'COMVEHINTSEMIGROUP7DISTANCEROUNDED',
        ComVehIntSemiGroup8DistanceRounded: 'COMVEHINTSEMIGROUP8DISTANCEROUNDED',
        ComVehIntSemiGroup9DistanceRounded: 'COMVEHINTSEMIGROUP8DISTANCEROUNDED',
        ComVehIntSemiGroup10DistanceRounded: 'COMVEHINTSEMIGROUP10DISTANCEROUNDED',
        ComVehIntSemiGroup11DistanceRounded: 'COMVEHINTSEMIGROUP11DISTANCEROUNDED',
        ComVehIntSemiGroup7NoOfAxles: 'COMVEHINTSEMIGROUP7NOOFAXLES',
        ComVehIntSemiGroup8NoOfAxles: 'COMVEHINTSEMIGROUP8NOOFAXLES',
        ComVehIntSemiGroup9NoOfAxles: 'COMVEHINTSEMIGROUP9NOOFAXLES',
        ComVehIntSemiGroup10NoOfAxles: 'COMVEHINTSEMIGROUP10NOOFAXLES',
        ComVehIntSemiGroup11NoOfAxles: 'COMVEHINTSEMIGROUP11NOOFAXLES',
        ComVehIntSemiGroup7Permissible: 'COMVEHINTSEMIGROUP7PERMISSIBLE',
        ComVehIntSemiGroup8Permissible: 'COMVEHINTSEMIGROUP8PERMISSIBLE',
        ComVehIntSemiGroup9Permissible: 'COMVEHINTSEMIGROUP9PERMISSIBLE',
        ComVehIntSemiGroup10Permissible: 'COMVEHINTSEMIGROUP10PERMISSIBLE',
        ComVehIntSemiGroup11Permissible: 'COMVEHINTSEMIGROUP11PERMISSIBLE',

        //2021.1
        CVInchesToMM: 'CVINCHESTOMM',
        CVFeetToInches: 'CVFEETTOINCHES',
        CVlbTokg: 'CVLBTOKG',
        Trailer1Length: 'TRAILER1LENGTH',
        Trailer2Length: 'TRAILER2LENGTH',
        KingpinToRearmostAxleSettingMax: 'KINGPINTOREARMOSTAXLESETTINGMAX',
        CargoCarryingLengthMax: 'CARGOCARRYINGLENGTHMAX',
        UVDimension: 'UVDIMENSION',
        UVWeight: 'UVWEIGHT',
        UVPercent: 'UVPERCENT',

        //2021.2
        Trailer1AxleLastToChassisEndMin: 'TRAILER1AXLELASTTOCHASSISENDMIN',
        Trailer1OverallHeightMin: 'TRAILER1OVERALLHEIGHTMIN',
        ShowConcessionalMassLimitsOption: 'SHOWCONCESSIONALMASSLIMITSOPTION',
        ConcessionalMassLimitsForCombination: 'CONCESSIONALMASSLIMITSFORCOMBINATION',
        HigherMassLimitsForCombination: 'HIGHERMASSLIMITSFORCOMBINATION',
        CombinationOverallWheelbase: 'COMBINATIONOVERALLWHEELBASE',
        VehicleFrontGrossPercent: 'VEHICLEFRONTGROSSPERCENT',
        VehicleRearGrossPercent: 'VEHICLEREARGROSSPERCENT',
        TrailerHitchLoadWeight: 'TRAILERHITCHLOADWEIGHT',
        TrailerHitchLoadPercent: 'TRAILERHITCHLOADPERCENT',
        VehicleStaticRolloverAngle: 'VEHICLESTATICROLLOVERANGLE',
        //VehicleMaxUnladenWeight: 'VEHICLEMAXUNLADENWEIGHT',
        ComVehicleVerticalCOG: 'COMVEHICLEVERTICALCOG',
        Trailer1KingpinLoadWeight: 'TRAILER1KINGPINLOADWEIGHT',
        Trailer1KingpinLoadPercent: 'TRAILER1KINGPINLOADPERCENT',
        Trailer2KingpinLoadWeight: 'TRAILER2KINGPINLOADWEIGHT',
        Trailer2KingpinLoadPercent: 'TRAILER2KINGPINLOADPERCENT',
        OverallTrailer1VerticalCOG: 'OVERALLTRAILER1VERTICALCOG',
        OverallTrailer2VerticalCOG: 'OVERALLTRAILER2VERTICALCOG',
        Trailer1StaticRolloverAngle: 'TRAILER1STATICROLLOVERANGLE',
        Trailer2StaticRolloverAngle: 'TRAILER2STATICROLLOVERANGLE',
        CombinationTurningRadiusCurbToCurb: 'COMBINATIONTURNINGRADIUSCURBTOCURB',
        CombinationTurningRadiusWallToWall: 'COMBINATIONTURNINGRADIUSWALLTOWALL',
        CombinationInnerRadius: 'COMBINATIONINNERRADIUS',
        ComVehOverallWidth: 'COMVEHOVERALLWIDTH',
        ComVehOverallHeight: 'COMVEHOVERALLHEIGHT',
        ComVehFrontalArea: 'COMVEHFRONTALAREA',
        ComVehOverallBridgePermissible: 'COMVEHOVERALLBRIDGEPERMISSIBLE',
        ComVehBodyGap: 'COMVEHBODYGAP',
        //ComVehDrawbarOverallHeight: 'COMVEHDRAWBAROVERALLHEIGHT',
        //ComVehDrawbarFrontalArea: 'COMVEHDRAWBARFRONTALAREA',
        //ComVehDrawbarOverallBridgePermissible: 'COMVEHDRAWBAROVERALLBRIDGEPERMISSIBLE',
        //ComVehDrawbarVehBodyGap: 'COMVEHDRAWBARVEHBODYGAP',
        //ComVehPupOverallHeight: 'COMVEHPUPOVERALLHEIGHT',
        //ComVehPupFrontalArea: 'COMVEHPUPFRONTALAREA',
        //ComVehPupOverallBridgePermissible: 'COMVEHPUPOVERALLBRIDGEPERMISSIBLE',
        //ComVehPupVehBodyGap: 'COMVEHPUPVEHBODYGAP',
        //ComVehSemiOverallHeight: 'COMVEHSEMIOVERALLHEIGHT',
        //ComVehSemiFrontalArea: 'COMVEHSEMIFRONTALAREA',
        //ComVehSemiOverallBridgePermissible: 'COMVEHSEMIOVERALLBRIDGEPERMISSIBLE',
        //ComVehIntSemiOverallHeight: 'COMVEHINTSEMIOVERALLHEIGHT',
        //ComVehIntSemiFrontalArea: 'COMVEHINTSEMIFRONTALAREA',
        //ComVehIntSemiOverallBridgePermissible: 'COMVEHIntSEMIOVERALLBRIDGEPERMISSIBLE',
        CombinationOverallHeight: 'COMBINATIONOVERALLHEIGHT',
        CombinationFrontalArea: 'COMBINATIONFRONTALAREA',
        AppLimitCombination: 'APPLIMITCOMBINATION',
        ComVehBodyPlusPayloadPercentFront: 'COMVEHBODYPLUSPAYLOADPERCENTFRONT',
        ComVehBodyPlusPayloadPercentRear: 'COMVEHBODYPLUSPAYLOADPERCENTREAR',
        ComVehRearOverhangPercent: 'COMVEHREAROVERHANGPERCENT',
        ComVehRearOverhang: 'COMVEHREAROVERHANG',

        //2021.3
        HookliftBodyOverhangMax: 'HOOKLIFTBODYOVERHANGMAX',

        //2022.1
        VehicleFrontAxlesNoOfAxles: 'VEHICLEFRONTAXLESNOOFAXLES',
        VehiclePusher1NoOfAxles: 'VEHICLEPUSHER1NOOFAXLES',
        VehiclePusher2NoOfAxles: 'VEHICLEPUSHER2NOOFAXLES',
        VehiclePusher3NoOfAxles: 'VEHICLEPUSHER3NOOFAXLES',
        VehicleRearRearOnlyNoOfAxles: 'VEHICLEREARREARONLYNOOFAXLES',
        VehicleTag1NoOfAxles: 'VEHICLETAG1NOOFAXLES',
        VehicleTag2NoOfAxles: 'VEHICLETAG2NOOFAXLES',
        VehicleTag3NoOfAxles: 'VEHICLETAG3NOOFAXLES',
        VehicleAllRearAxlesNoOfAxles: 'VEHICLEALLREARAXLESNOOFAXLES',

        VehicleFrontAxlesNoOfTyresPerAxle: 'VEHICLEFRONTAXLESNOOFTYRESPERAXLE',
        VehiclePusher1NoOfTyresPerAxle: 'VEHICLEPUSHER1NOOFTYRESPERAXLE',
        VehiclePusher2NoOfTyresPerAxle: 'VEHICLEPUSHER2NOOFTYRESPERAXLE',
        VehiclePusher3NoOfTyresPerAxle: 'VEHICLEPUSHER3NOOFTYRESPERAXLE',
        VehicleRearRearOnlyNoOfTyresPerAxle: 'VEHICLEREARREARONLYNOOFTYRESPERAXLE',
        VehicleTag1NoOfTyresPerAxle: 'VEHICLETAG1NOOFTYRESPERAXLE',
        VehicleTag2NoOfTyresPerAxle: 'VEHICLETAG2NOOFTYRESPERAXLE',
        VehicleTag3NoOfTyresPerAxle: 'VEHICLETAG3NOOFTYRESPERAXLE',
        VehicleAllRearAxlesNoOfTyresPerAxle: 'VEHICLEALLREARAXLESNOOFTYRESPERAXLE',

        VehicleFrontAxlesWidthPerTyre: 'VEHICLEFRONTAXLESWIDTHPERTYRE',
        VehiclePusher1WidthPerTyre: 'VEHICLEPUSHER1WIDTHPERTYRE',
        VehiclePusher2WidthPerTyre: 'VEHICLEPUSHER2WIDTHPERTYRE',
        VehiclePusher3WidthPerTyre: 'VEHICLEPUSHER3WIDTHPERTYRE',
        VehicleRearRearOnlyWidthPerTyre: 'VEHICLEREARREARONLYWIDTHPERTYRE',
        VehicleTag1WidthPerTyre: 'VEHICLETAG1WIDTHPERTYRE',
        VehicleTag2WidthPerTyre: 'VEHICLETAG2WIDTHPERTYRE',
        VehicleTag3WidthPerTyre: 'VEHICLETAG3WIDTHPERTYRE',
        VehicleAllRearAxlesWidthPerTyre: 'VEHICLEALLREARAXLESWIDTHPERTYRE',

        VehicleFrontAxlesLifting: 'VEHICLEFRONTAXLESLIFTING',
        VehiclePusher1Lifting: 'VEHICLEPUSHER1LIFTING',
        VehiclePusher2Lifting: 'VEHICLEPUSHER2LIFTING',
        VehiclePusher3Lifting: 'VEHICLEPUSHER3LIFTING',
        VehicleRearRearOnlyLifting: 'VEHICLEREARREARONLYLIFTING',
        VehicleTag1Lifting: 'VEHICLETAG1LIFTING',
        VehicleTag2Lifting: 'VEHICLETAG2LIFTING',
        VehicleTag3Lifting: 'VEHICLETAG3LIFTING',
        VehicleAllRearAxlesLifting: 'VEHICLEALLREARAXLESLIFTING',

        VehicleFrontAxlesRaised: 'VEHICLEFRONTAXLESRAISED',
        VehiclePusher1Raised: 'VEHICLEPUSHER1RAISED',
        VehiclePusher2Raised: 'VEHICLEPUSHER2RAISED',
        VehiclePusher3Raised: 'VEHICLEPUSHER3RAISED',
        VehicleRearRearOnlyRaised: 'VEHICLEREARREARONLYRAISED',
        VehicleTag1Raised: 'VEHICLETAG1RAISED',
        VehicleTag2Raised: 'VEHICLETAG2RAISED',
        VehicleTag3Raised: 'VEHICLETAG3RAISED',
        VehicleAllRearAxlesRaised: 'VEHICLEALLREARAXLESRAISED',

        VehicleFrontAxlesSteering: 'VEHICLEFRONTAXLESSTEERING',
        VehiclePusher1Steering: 'VEHICLEPUSHER1STEERING',
        VehiclePusher2Steering: 'VEHICLEPUSHER2STEERING',
        VehiclePusher3Steering: 'VEHICLEPUSHER3STEERING',
        VehicleRearRearOnlySteering: 'VEHICLEREARREARONLYSTEERING',
        VehicleTag1Steering: 'VEHICLETAG1STEERING',
        VehicleTag2Steering: 'VEHICLETAG2STEERING',
        VehicleTag3Steering: 'VEHICLETAG3STEERING',
        VehicleAllRearAxlesSteering: 'VEHICLEALLREARAXLESSTEERING',

        VehicleFrontAxlesDriven: 'VEHICLEFRONTAXLESDRIVEN',
        VehiclePusher1Driven: 'VEHICLEPUSHER1DRIVEN',
        VehiclePusher2Driven: 'VEHICLEPUSHER2DRIVEN',
        VehiclePusher3Driven: 'VEHICLEPUSHER3DRIVEN',
        VehicleRearRearOnlyDriven: 'VEHICLEREARREARONLYDRIVEN',
        VehicleTag1Driven: 'VEHICLETAG1DRIVEN',
        VehicleTag2Driven: 'VEHICLETAG2DRIVEN',
        VehicleTag3Driven: 'VEHICLETAG3DRIVEN',
        VehicleAllRearAxlesDriven: 'VEHICLEALLREARAXLESDRIVEN',

        ComVehGroup3NoOfTyresPerAxle: 'COMVEHGROUP3NOOFTYRESPERAXLE',
        ComVehGroup3WidthPerTyre: 'COMVEHGROUP3WIDTHPERTYRE',
        ComVehGroup3Lifting: 'COMVEHGROUP3LIFTING',
        ComVehGroup3Raised: 'COMVEHGROUP3RAISED',
        ComVehGroup3Steering: 'COMVEHGROUP3STEERING',

        ComVehGroup4NoOfTyresPerAxle: 'COMVEHGROUP4NOOFTYRESPERAXLE',
        ComVehGroup4WidthPerTyre: 'COMVEHGROUP4WIDTHPERTYRE',
        ComVehGroup4Lifting: 'COMVEHGROUP4LIFTING',
        ComVehGroup4Raised: 'COMVEHGROUP4RAISED',
        ComVehGroup4Steering: 'COMVEHGROUP4STEERING',

        ComVehGroup5DistanceRounded: 'COMVEHGROUP5DISTANCEROUNDED',
        ComVehGroup5NoOfTyresPerAxle: 'COMVEHGROUP5NOOFTYRESPERAXLE',
        ComVehGroup5WidthPerTyre: 'COMVEHGROUP5WIDTHPERTYRE',
        ComVehGroup5Lifting: 'COMVEHGROUP5LIFTING',
        ComVehGroup5Raised: 'COMVEHGROUP5RAISED',
        ComVehGroup5Steering: 'COMVEHGROUP5STEERING',

        ComVehGroup6DistanceRounded: 'COMVEHGROUP6DISTANCEROUNDED',
        ComVehGroup6NoOfTyresPerAxle: 'COMVEHGROUP6NOOFTYRESPERAXLE',
        ComVehGroup6WidthPerTyre: 'COMVEHGROUP6WIDTHPERTYRE',
        ComVehGroup6Lifting: 'COMVEHGROUP6LIFTING',
        ComVehGroup6Raised: 'COMVEHGROUP6RAISED',
        ComVehGroup6Steering: 'COMVEHGROUP6STEERING',

        ComVehGroup7DistanceRounded: 'COMVEHGROUP7DISTANCEROUNDED',
        ComVehGroup7NoOfTyresPerAxle: 'COMVEHGROUP7NOOFTYRESPERAXLE',
        ComVehGroup7WidthPerTyre: 'COMVEHGROUP7WIDTHPERTYRE',
        ComVehGroup7Lifting: 'COMVEHGROUP7LIFTING',
        ComVehGroup7Raised: 'COMVEHGROUP7RAISED',
        ComVehGroup7Steering: 'COMVEHGROUP7STEERING',

        ComVehGroup8DistanceRounded: 'COMVEHGROUP8DISTANCEROUNDED',
        ComVehGroup8NoOfTyresPerAxle: 'COMVEHGROUP8NOOFTYRESPERAXLE',
        ComVehGroup8WidthPerTyre: 'COMVEHGROUP8WIDTHPERTYRE',
        ComVehGroup8Lifting: 'COMVEHGROUP8LIFTING',
        ComVehGroup8Raised: 'COMVEHGROUP8RAISED',
        ComVehGroup8Steering: 'COMVEHGROUP8STEERING',

        ComVehGroup9DistanceRounded: 'COMVEHGROUP9DISTANCEROUNDED',
        ComVehGroup9NoOfTyresPerAxle: 'COMVEHGROUP9NOOFTYRESPERAXLE',
        ComVehGroup9WidthPerTyre: 'COMVEHGROUP9WIDTHPERTYRE',
        ComVehGroup9Lifting: 'COMVEHGROUP9LIFTING',
        ComVehGroup9Raised: 'COMVEHGROUP9RAISED',
        ComVehGroup9Steering: 'COMVEHGROUP9STEERING',

        ComVehGroup10DistanceRounded: 'COMVEHGROUP10DISTANCEROUNDED',
        ComVehGroup10NoOfTyresPerAxle: 'COMVEHGROUP10NOOFTYRESPERAXLE',
        ComVehGroup10WidthPerTyre: 'COMVEHGROUP10WIDTHPERTYRE',
        ComVehGroup10Lifting: 'COMVEHGROUP10LIFTING',
        ComVehGroup10Raised: 'COMVEHGROUP10RAISED',
        ComVehGroup10Steering: 'COMVEHGROUP10STEERING',


        ComVehDrawbarGroup8NoOfTyresPerAxle: 'COMVEHDRAWBARGROUP8NOOFTYRESPERAXLE',
        ComVehDrawbarGroup8WidthPerTyre: 'COMVEHDRAWBARGROUP8WIDTHPERTYRE',
        ComVehDrawbarGroup8Lifting: 'COMVEHDRAWBARGROUP8LIFTING',
        ComVehDrawbarGroup8Raised: 'COMVEHDRAWBARGROUP8RAISED',
        ComVehDrawbarGroup8Steering: 'COMVEHDRAWBARGROUP8STEERING',

        ComVehDrawbarGroup9NoOfTyresPerAxle: 'COMVEHDRAWBARGROUP9NOOFTYRESPERAXLE',
        ComVehDrawbarGroup9WidthPerTyre: 'COMVEHDRAWBARGROUP9WIDTHPERTYRE',
        ComVehDrawbarGroup9Lifting: 'COMVEHDRAWBARGROUP9LIFTING',
        ComVehDrawbarGroup9Raised: 'COMVEHDRAWBARGROUP9RAISED',
        ComVehDrawbarGroup9Steering: 'COMVEHDRAWBARGROUP9STEERING',
        
        ComVehDrawbarGroup10NoOfTyresPerAxle: 'COMVEHDRAWBARGROUP10NOOFTYRESPERAXLE',
        ComVehDrawbarGroup10WidthPerTyre: 'COMVEHDRAWBARGROUP10WIDTHPERTYRE',
        ComVehDrawbarGroup10Lifting: 'COMVEHDRAWBARGROUP10LIFTING',
        ComVehDrawbarGroup10Raised: 'COMVEHDRAWBARGROUP10RAISED',
        ComVehDrawbarGroup10Steering: 'COMVEHDRAWBARGROUP10STEERING',

        ComVehDrawbarGroup11NoOfTyresPerAxle: 'COMVEHDRAWBARGROUP11NOOFTYRESPERAXLE',
        ComVehDrawbarGroup11WidthPerTyre: 'COMVEHDRAWBARGROUP11WIDTHPERTYRE',
        ComVehDrawbarGroup11Lifting: 'COMVEHDRAWBARGROUP11LIFTING',
        ComVehDrawbarGroup11Raised: 'COMVEHDRAWBARGROUP11RAISED',
        ComVehDrawbarGroup11Steering: 'COMVEHDRAWBARGROUP11STEERING',

        ComVehDrawbarGroup12DistanceRounded: 'COMVEHDRAWBARGROUP12DISTANCEROUNDED',
        ComVehDrawbarGroup12NoOfTyresPerAxle: 'COMVEHDRAWBARGROUP12NOOFTYRESPERAXLE',
        ComVehDrawbarGroup12WidthPerTyre: 'COMVEHDRAWBARGROUP12WIDTHPERTYRE',
        ComVehDrawbarGroup12Lifting: 'COMVEHDRAWBARGROUP12LIFTING',
        ComVehDrawbarGroup12Raised: 'COMVEHDRAWBARGROUP12RAISED',
        ComVehDrawbarGroup12Steering: 'COMVEHDRAWBARGROUP12STEERING',

        ComVehDrawbarGroup13DistanceRounded: 'COMVEHDRAWBARGROUP13DISTANCEROUNDED',
        ComVehDrawbarGroup13NoOfTyresPerAxle: 'COMVEHDRAWBARGROUP13NOOFTYRESPERAXLE',
        ComVehDrawbarGroup13WidthPerTyre: 'COMVEHDRAWBARGROUP13WIDTHPERTYRE',
        ComVehDrawbarGroup13Lifting: 'COMVEHDRAWBARGROUP13LIFTING',
        ComVehDrawbarGroup13Raised: 'COMVEHDRAWBARGROUP13RAISED',
        ComVehDrawbarGroup13Steering: 'COMVEHDRAWBARGROUP13STEERING',

        ComVehDrawbarGroup14DistanceRounded: 'COMVEHDRAWBARGROUP14DISTANCEROUNDED',
        ComVehDrawbarGroup14NoOfTyresPerAxle: 'COMVEHDRAWBARGROUP14NOOFTYRESPERAXLE',
        ComVehDrawbarGroup14WidthPerTyre: 'COMVEHDRAWBARGROUP14WIDTHPERTYRE',
        ComVehDrawbarGroup14Lifting: 'COMVEHDRAWBARGROUP14LIFTING',
        ComVehDrawbarGroup14Raised: 'COMVEHDRAWBARGROUP14RAISED',
        ComVehDrawbarGroup14Steering: 'COMVEHDRAWBARGROUP14STEERING',

        ComVehDrawbarGroup15DistanceRounded: 'COMVEHDRAWBARGROUP15DISTANCEROUNDED',
        ComVehDrawbarGroup15NoOfTyresPerAxle: 'COMVEHDRAWBARGROUP15NOOFTYRESPERAXLE',
        ComVehDrawbarGroup15WidthPerTyre: 'COMVEHDRAWBARGROUP15WIDTHPERTYRE',
        ComVehDrawbarGroup15Lifting: 'COMVEHDRAWBARGROUP15LIFTING',
        ComVehDrawbarGroup15Raised: 'COMVEHDRAWBARGROUP15RAISED',
        ComVehDrawbarGroup15Steering: 'COMVEHDRAWBARGROUP15STEERING',

        ComVehDrawbarGroup16DistanceRounded: 'COMVEHDRAWBARGROUP16DISTANCEROUNDED',
        ComVehDrawbarGroup16NoOfTyresPerAxle: 'COMVEHDRAWBARGROUP16NOOFTYRESPERAXLE',
        ComVehDrawbarGroup16WidthPerTyre: 'COMVEHDRAWBARGROUP16WIDTHPERTYRE',
        ComVehDrawbarGroup16Lifting: 'COMVEHDRAWBARGROUP16LIFTING',
        ComVehDrawbarGroup16Raised: 'COMVEHDRAWBARGROUP16RAISED',
        ComVehDrawbarGroup16Steering: 'COMVEHDRAWBARGROUP16STEERING',

        ComVehDrawbarGroup17DistanceRounded: 'COMVEHDRAWBARGROUP17DISTANCEROUNDED',
        ComVehDrawbarGroup17NoOfTyresPerAxle: 'COMVEHDRAWBARGROUP17NOOFTYRESPERAXLE',
        ComVehDrawbarGroup17WidthPerTyre: 'COMVEHDRAWBARGROUP17WIDTHPERTYRE',
        ComVehDrawbarGroup17Lifting: 'COMVEHDRAWBARGROUP17LIFTING',
        ComVehDrawbarGroup17Raised: 'COMVEHDRAWBARGROUP17RAISED',
        ComVehDrawbarGroup17Steering: 'COMVEHDRAWBARGROUP17STEERING',

    
        ComVehPupGroup5NoOfTyresPerAxle: 'COMVEHPUPGROUP5NOOFTYRESPERAXLE',
        ComVehPupGroup5WidthPerTyre: 'COMVEHPUPGROUP5WIDTHPERTYRE',
        ComVehPupGroup5Lifting: 'COMVEHPUPGROUP5LIFTING',
        ComVehPupGroup5Raised: 'COMVEHPUPGROUP5RAISED',
        ComVehPupGroup5Steering: 'COMVEHPUPGROUP5STEERING',

        ComVehPupGroup6NoOfTyresPerAxle: 'COMVEHPUPGROUP6NOOFTYRESPERAXLE',
        ComVehPupGroup6WidthPerTyre: 'COMVEHPUPGROUP6WIDTHPERTYRE',
        ComVehPupGroup6Lifting: 'COMVEHPUPGROUP6LIFTING',
        ComVehPupGroup6Raised: 'COMVEHPUPGROUP6RAISED',
        ComVehPupGroup6Steering: 'COMVEHPUPGROUP6STEERING',

        ComVehPupGroup7NoOfTyresPerAxle: 'COMVEHPUPGROUP7NOOFTYRESPERAXLE',
        ComVehPupGroup7WidthPerTyre: 'COMVEHPUPGROUP7WIDTHPERTYRE',
        ComVehPupGroup7Lifting: 'COMVEHPUPGROUP7LIFTING',
        ComVehPupGroup7Raised: 'COMVEHPUPGROUP7RAISED',
        ComVehPupGroup7Steering: 'COMVEHPUPGROUP7STEERING',

        ComVehPupGroup8DistanceRounded: 'COMVEHPUPGROUP8DISTANCEROUNDED',
        ComVehPupGroup8NoOfTyresPerAxle: 'COMVEHPUPGROUP8NOOFTYRESPERAXLE',
        ComVehPupGroup8WidthPerTyre: 'COMVEHPUPGROUP8WIDTHPERTYRE',
        ComVehPupGroup8Lifting: 'COMVEHPUPGROUP8LIFTING',
        ComVehPupGroup8Raised: 'COMVEHPUPGROUP8RAISED',
        ComVehPupGroup8Steering: 'COMVEHPUPGROUP8STEERING',

        ComVehPupGroup9DistanceRounded: 'COMVEHPUPGROUP9DISTANCEROUNDED',
        ComVehPupGroup9NoOfTyresPerAxle: 'COMVEHPUPGROUP9NOOFTYRESPERAXLE',
        ComVehPupGroup9WidthPerTyre: 'COMVEHPUPGROUP9WIDTHPERTYRE',
        ComVehPupGroup9Lifting: 'COMVEHPUPGROUP9LIFTING',
        ComVehPupGroup9Raised: 'COMVEHPUPGROUP9RAISED',
        ComVehPupGroup9Steering: 'COMVEHPUPGROUP9STEERING',

        ComVehPupGroup10DistanceRounded: 'COMVEHPUPGROUP10DISTANCEROUNDED',
        ComVehPupGroup10NoOfTyresPerAxle: 'COMVEHPUPGROUP10NOOFTYRESPERAXLE',
        ComVehPupGroup10WidthPerTyre: 'COMVEHPUPGROUP10WIDTHPERTYRE',
        ComVehPupGroup10Lifting: 'COMVEHPUPGROUP10LIFTING',
        ComVehPupGroup10Raised: 'COMVEHPUPGROUP10RAISED',
        ComVehPupGroup10Steering: 'COMVEHPUPGROUP10STEERING',

        ComVehPupGroup11DistanceRounded: 'COMVEHPUPGROUP11DISTANCEROUNDED',
        ComVehPupGroup11NoOfTyresPerAxle: 'COMVEHPUPGROUP11NOOFTYRESPERAXLE',
        ComVehPupGroup11WidthPerTyre: 'COMVEHPUPGROUP11WIDTHPERTYRE',
        ComVehPupGroup11Lifting: 'COMVEHPUPGROUP11LIFTING',
        ComVehPupGroup11Raised: 'COMVEHPUPGROUP11RAISED',
        ComVehPupGroup11Steering: 'COMVEHPUPGROUP11STEERING',

        ComVehPupGroup12DistanceRounded: 'COMVEHPUPGROUP12DISTANCEROUNDED',
        ComVehPupGroup12NoOfTyresPerAxle: 'COMVEHPUPGROUP12NOOFTYRESPERAXLE',
        ComVehPupGroup12WidthPerTyre: 'COMVEHPUPGROUP12WIDTHPERTYRE',
        ComVehPupGroup12Lifting: 'COMVEHPUPGROUP12LIFTING',
        ComVehPupGroup12Raised: 'COMVEHPUPGROUP12RAISED',
        ComVehPupGroup12Steering: 'COMVEHPUPGROUP12STEERING',

        ComVehPupGroup13DistanceRounded: 'COMVEHPUPGROUP13DISTANCEROUNDED',
        ComVehPupGroup13NoOfTyresPerAxle: 'COMVEHPUPGROUP13NOOFTYRESPERAXLE',
        ComVehPupGroup13WidthPerTyre: 'COMVEHPUPGROUP13WIDTHPERTYRE',
        ComVehPupGroup13Lifting: 'COMVEHPUPGROUP13LIFTING',
        ComVehPupGroup13Raised: 'COMVEHPUPGROUP13RAISED',
        ComVehPupGroup13Steering: 'COMVEHPUPGROUP13STEERING',


        ComVehSemiGroup5NoOfTyresPerAxle: 'COMVEHSEMIGROUP5NOOFTYRESPERAXLE',
        ComVehSemiGroup5WidthPerTyre: 'COMVEHSEMIGROUP5WIDTHPERTYRE',
        ComVehSemiGroup5Lifting: 'COMVEHSEMIGROUP5LIFTING',
        ComVehSemiGroup5Raised: 'COMVEHSEMIGROUP5RAISED',
        ComVehSemiGroup5Steering: 'COMVEHSEMIGROUP5STEERING',

        ComVehSemiGroup6NoOfTyresPerAxle: 'COMVEHSEMIGROUP6NOOFTYRESPERAXLE',
        ComVehSemiGroup6WidthPerTyre: 'COMVEHSEMIGROUP6WIDTHPERTYRE',
        ComVehSemiGroup6Lifting: 'COMVEHSEMIGROUP6LIFTING',
        ComVehSemiGroup6Raised: 'COMVEHSEMIGROUP6RAISED',
        ComVehSemiGroup6Steering: 'COMVEHSEMIGROUP6STEERING',

        ComVehSemiGroup7NoOfTyresPerAxle: 'COMVEHSEMIGROUP7NOOFTYRESPERAXLE',
        ComVehSemiGroup7WidthPerTyre: 'COMVEHSEMIGROUP7WIDTHPERTYRE',
        ComVehSemiGroup7Lifting: 'COMVEHSEMIGROUP7LIFTING',
        ComVehSemiGroup7Raised: 'COMVEHSEMIGROUP7RAISED',
        ComVehSemiGroup7Steering: 'COMVEHSEMIGROUP7STEERING',

        ComVehSemiGroup8DistanceRounded: 'COMVEHSEMIGROUP8DISTANCEROUNDED',
        ComVehSemiGroup8NoOfTyresPerAxle: 'COMVEHSEMIGROUP8NOOFTYRESPERAXLE',
        ComVehSemiGroup8WidthPerTyre: 'COMVEHSEMIGROUP8WIDTHPERTYRE',
        ComVehSemiGroup8Lifting: 'COMVEHSEMIGROUP8LIFTING',
        ComVehSemiGroup8Raised: 'COMVEHSEMIGROUP8RAISED',
        ComVehSemiGroup8Steering: 'COMVEHSEMIGROUP8STEERING',

        ComVehSemiGroup9DistanceRounded: 'COMVEHSEMIGROUP9DISTANCEROUNDED',
        ComVehSemiGroup9NoOfTyresPerAxle: 'COMVEHSEMIGROUP9NOOFTYRESPERAXLE',
        ComVehSemiGroup9WidthPerTyre: 'COMVEHSEMIGROUP9WIDTHPERTYRE',
        ComVehSemiGroup9Lifting: 'COMVEHSEMIGROUP9LIFTING',
        ComVehSemiGroup9Raised: 'COMVEHSEMIGROUP9RAISED',
        ComVehSemiGroup9Steering: 'COMVEHSEMIGROUP9STEERING',

        ComVehSemiGroup10DistanceRounded: 'COMVEHSEMIGROUP10DISTANCEROUNDED',
        ComVehSemiGroup10NoOfTyresPerAxle: 'COMVEHSEMIGROUP10NOOFTYRESPERAXLE',
        ComVehSemiGroup10WidthPerTyre: 'COMVEHSEMIGROUP10WIDTHPERTYRE',
        ComVehSemiGroup10Lifting: 'COMVEHSEMIGROUP10LIFTING',
        ComVehSemiGroup10Raised: 'COMVEHSEMIGROUP10RAISED',
        ComVehSemiGroup10Steering: 'COMVEHSEMIGROUP10STEERING',

        ComVehSemiGroup11DistanceRounded: 'COMVEHSEMIGROUP11DISTANCEROUNDED',
        ComVehSemiGroup11NoOfTyresPerAxle: 'COMVEHSEMIGROUP11NOOFTYRESPERAXLE',
        ComVehSemiGroup11WidthPerTyre: 'COMVEHSEMIGROUP11WIDTHPERTYRE',
        ComVehSemiGroup11Lifting: 'COMVEHSEMIGROUP11LIFTING',
        ComVehSemiGroup11Raised: 'COMVEHSEMIGROUP11RAISED',
        ComVehSemiGroup11Steering: 'COMVEHSEMIGROUP11STEERING',

        ComVehSemiGroup12DistanceRounded: 'COMVEHSEMIGROUP12DISTANCEROUNDED',
        ComVehSemiGroup12NoOfTyresPerAxle: 'COMVEHSEMIGROUP12NOOFTYRESPERAXLE',
        ComVehSemiGroup12WidthPerTyre: 'COMVEHSEMIGROUP12WIDTHPERTYRE',
        ComVehSemiGroup12Lifting: 'COMVEHSEMIGROUP12LIFTING',
        ComVehSemiGroup12Raised: 'COMVEHSEMIGROUP12RAISED',
        ComVehSemiGroup12Steering: 'COMVEHSEMIGROUP12STEERING',

        ComVehSemiGroup13DistanceRounded: 'COMVEHSEMIGROUP13DISTANCEROUNDED',
        ComVehSemiGroup13NoOfTyresPerAxle: 'COMVEHSEMIGROUP13NOOFTYRESPERAXLE',
        ComVehSemiGroup13WidthPerTyre: 'COMVEHSEMIGROUP13WIDTHPERTYRE',
        ComVehSemiGroup13Lifting: 'COMVEHSEMIGROUP13LIFTING',
        ComVehSemiGroup13Raised: 'COMVEHSEMIGROUP13RAISED',
        ComVehSemiGroup13Steering: 'COMVEHSEMIGROUP13STEERING',


        ComVehIntSemiGroup8NoOfTyresPerAxle: 'COMVEHINTSEMIGROUP8NOOFTYRESPERAXLE',
        ComVehIntSemiGroup8WidthPerTyre: 'COMVEHINTSEMIGROUP8WIDTHPERTYRE',
        ComVehIntSemiGroup8Lifting: 'COMVEHINTSEMIGROUP8LIFTING',
        ComVehIntSemiGroup8Raised: 'COMVEHINTSEMIGROUP8RAISED',
        ComVehIntSemiGroup8Steering: 'COMVEHINTSEMIGROUP8STEERING',

        ComVehIntSemiGroup9NoOfTyresPerAxle: 'COMVEHINTSEMIGROUP9NOOFTYRESPERAXLE',
        ComVehIntSemiGroup9WidthPerTyre: 'COMVEHINTSEMIGROUP9WIDTHPERTYRE',
        ComVehIntSemiGroup9Lifting: 'COMVEHINTSEMIGROUP9LIFTING',
        ComVehIntSemiGroup9Raised: 'COMVEHINTSEMIGROUP9RAISED',
        ComVehIntSemiGroup9Steering: 'COMVEHINTSEMIGROUP9STEERING',

        ComVehIntSemiGroup10NoOfTyresPerAxle: 'COMVEHINTSEMIGROUP10NOOFTYRESPERAXLE',
        ComVehIntSemiGroup10WidthPerTyre: 'COMVEHINTSEMIGROUP10WIDTHPERTYRE',
        ComVehIntSemiGroup10Lifting: 'COMVEHINTSEMIGROUP10LIFTING',
        ComVehIntSemiGroup10Raised: 'COMVEHINTSEMIGROUP10RAISED',
        ComVehIntSemiGroup10Steering: 'COMVEHINTSEMIGROUP10STEERING',

        ComVehIntSemiGroup11NoOfTyresPerAxle: 'COMVEHINTSEMIGROUP11NOOFTYRESPERAXLE',
        ComVehIntSemiGroup11WidthPerTyre: 'COMVEHINTSEMIGROUP11WIDTHPERTYRE',
        ComVehIntSemiGroup11Lifting: 'COMVEHINTSEMIGROUP11LIFTING',
        ComVehIntSemiGroup11Raised: 'COMVEHINTSEMIGROUP11RAISED',
        ComVehIntSemiGroup11Steering: 'COMVEHINTSEMIGROUP11STEERING',

        ComVehIntSemiGroup12DistanceRounded: 'COMVEHINTSEMIGROUP12DISTANCEROUNDED',
        ComVehIntSemiGroup12NoOfTyresPerAxle: 'COMVEHINTSEMIGROUP12NOOFTYRESPERAXLE',
        ComVehIntSemiGroup12WidthPerTyre: 'COMVEHINTSEMIGROUP12WIDTHPERTYRE',
        ComVehIntSemiGroup12Lifting: 'COMVEHINTSEMIGROUP12LIFTING',
        ComVehIntSemiGroup12Raised: 'COMVEHINTSEMIGROUP12RAISED',
        ComVehIntSemiGroup12Steering: 'COMVEHINTSEMIGROUP12STEERING',

        ComVehIntSemiGroup13DistanceRounded: 'COMVEHINTSEMIGROUP13DISTANCEROUNDED',
        ComVehIntSemiGroup13NoOfTyresPerAxle: 'COMVEHINTSEMIGROUP13NOOFTYRESPERAXLE',
        ComVehIntSemiGroup13WidthPerTyre: 'COMVEHINTSEMIGROUP13WIDTHPERTYRE',
        ComVehIntSemiGroup13Lifting: 'COMVEHINTSEMIGROUP13LIFTING',
        ComVehIntSemiGroup13Raised: 'COMVEHINTSEMIGROUP13RAISED',
        ComVehIntSemiGroup13Steering: 'COMVEHINTSEMIGROUP13STEERING',

        ComVehIntSemiGroup14DistanceRounded: 'COMVEHINTSEMIGROUP14DISTANCEROUNDED',
        ComVehIntSemiGroup14NoOfTyresPerAxle: 'COMVEHINTSEMIGROUP14NOOFTYRESPERAXLE',
        ComVehIntSemiGroup14WidthPerTyre: 'COMVEHINTSEMIGROUP14WIDTHPERTYRE',
        ComVehIntSemiGroup14Lifting: 'COMVEHINTSEMIGROUP14LIFTING',
        ComVehIntSemiGroup14Raised: 'COMVEHINTSEMIGROUP14RAISED',
        ComVehIntSemiGroup14Steering: 'COMVEHINTSEMIGROUP14STEERING',

        ComVehIntSemiGroup15DistanceRounded: 'COMVEHINTSEMIGROUP15DISTANCEROUNDED',
        ComVehIntSemiGroup15NoOfTyresPerAxle: 'COMVEHINTSEMIGROUP15NOOFTYRESPERAXLE',
        ComVehIntSemiGroup15WidthPerTyre: 'COMVEHINTSEMIGROUP15WIDTHPERTYRE',
        ComVehIntSemiGroup15Lifting: 'COMVEHINTSEMIGROUP15LIFTING',
        ComVehIntSemiGroup15Raised: 'COMVEHINTSEMIGROUP15RAISED',
        ComVehIntSemiGroup15Steering: 'COMVEHINTSEMIGROUP15STEERING',

        ComVehIntSemiGroup16DistanceRounded: 'COMVEHINTSEMIGROUP16DISTANCEROUNDED',
        ComVehIntSemiGroup16NoOfTyresPerAxle: 'COMVEHINTSEMIGROUP16NOOFTYRESPERAXLE',
        ComVehIntSemiGroup16WidthPerTyre: 'COMVEHINTSEMIGROUP16WIDTHPERTYRE',
        ComVehIntSemiGroup16Lifting: 'COMVEHINTSEMIGROUP16LIFTING',
        ComVehIntSemiGroup16Raised: 'COMVEHINTSEMIGROUP16RAISED',
        ComVehIntSemiGroup16Steering: 'COMVEHINTSEMIGROUP16STEERING',

        ComVehIntSemiGroup17DistanceRounded: 'COMVEHINTSEMIGROUP17DISTANCEROUNDED',
        ComVehIntSemiGroup17NoOfTyresPerAxle: 'COMVEHINTSEMIGROUP17NOOFTYRESPERAXLE',
        ComVehIntSemiGroup17WidthPerTyre: 'COMVEHINTSEMIGROUP17WIDTHPERTYRE',
        ComVehIntSemiGroup17Lifting: 'COMVEHINTSEMIGROUP17LIFTING',
        ComVehIntSemiGroup17Raised: 'COMVEHINTSEMIGROUP17RAISED',
        ComVehIntSemiGroup17Steering: 'COMVEHINTSEMIGROUP17STEERING',

        ComVehIntSemiGroup12Permissible: 'COMVEHINTSEMIGROUP12PERMISSIBLE',
        ComVehIntSemiGroup13Permissible: 'COMVEHINTSEMIGROUP13PERMISSIBLE',
        ComVehIntSemiGroup14Permissible: 'COMVEHINTSEMIGROUP14PERMISSIBLE',
        ComVehIntSemiGroup15Permissible: 'COMVEHINTSEMIGROUP15PERMISSIBLE',
        ComVehIntSemiGroup16Permissible: 'COMVEHINTSEMIGROUP16PERMISSIBLE',
        ComVehIntSemiGroup17Permissible: 'COMVEHINTSEMIGROUP17PERMISSIBLE',

        ComVehSemiGroup8Permissible: 'COMVEHSEMIGROUP8PERMISSIBLE',
        ComVehSemiGroup9Permissible: 'COMVEHSEMIGROUP9PERMISSIBLE',
        ComVehSemiGroup10Permissible: 'COMVEHSEMIGROUP10PERMISSIBLE',
        ComVehSemiGroup11Permissible: 'COMVEHSEMIGROUP11PERMISSIBLE',
        ComVehSemiGroup12Permissible: 'COMVEHSEMIGROUP12PERMISSIBLE',
        ComVehSemiGroup13Permissible: 'COMVEHSEMIGROUP13PERMISSIBLE',

        ComVehPupGroup8Permissible: 'COMVEHPUPGROUP8PERMISSIBLE',
        ComVehPupGroup9Permissible: 'COMVEHPUPGROUP9PERMISSIBLE',
        ComVehPupGroup10Permissible: 'COMVEHPUPGROUP10PERMISSIBLE',
        ComVehPupGroup11Permissible: 'COMVEHPUPGROUP11PERMISSIBLE',
        ComVehPupGroup12Permissible: 'COMVEHPUPGROUP12PERMISSIBLE',
        ComVehPupGroup13Permissible: 'COMVEHPUPGROUP13PERMISSIBLE',

        ComVehDrawbarGroup12Permissible: 'COMVEHDRAWBARGROUP12PERMISSIBLE',
        ComVehDrawbarGroup13Permissible: 'COMVEHDRAWBARGROUP13PERMISSIBLE',
        ComVehDrawbarGroup14Permissible: 'COMVEHDRAWBARGROUP14PERMISSIBLE',
        ComVehDrawbarGroup15Permissible: 'COMVEHDRAWBARGROUP15PERMISSIBLE',
        ComVehDrawbarGroup16Permissible: 'COMVEHDRAWBARGROUP16PERMISSIBLE',
        ComVehDrawbarGroup17Permissible: 'COMVEHDRAWBARGROUP17PERMISSIBLE',

        Trailer1TyreWidthFront: 'TRAILER1TYREWIDTHFRONT',
        Trailer1TyreWidthRear: 'TRAILER1TYREWIDTHREAR',
        Trailer2TyreWidthRear: 'TRAILER2TYREWIDTHREAR',

        //2023.3
        UsesDataDrivenRulesMethod: 'USESDATADRIVENRULESMETHOD',
        VehicleChassisHeight: 'VEHICLECHASSISHEIGHT',
        VehicleBodySubframeHeight: 'VEHICLEBODYSUBFRAMEHEIGHT',
        VehicleBodyHeightExternal: 'VEHICLEBODYHEIGHTEXTERNAL',
        Trailer1ChassisHeight: 'TRAILER1CHASSISHEIGHT',
        Trailer1BodyHeightExternal: 'TRAILER1BODYHEIGHTEXTERNAL',
        Trailer2ChassisHeight: 'TRAILER2CHASSISHEIGHT',
        Trailer2BodyHeightExternal: 'TRAILER2BODYHEIGHTEXTERNAL',
        VehicleHitchOffset: 'VEHICLEHITCHOFFSET',
        BodySubTypeVehicle: 'BODYSUBTYPEVEHICLE',
        LegislationDescription: 'LEGISLATIONDESCRIPTION',
        // LegislationDate: 'LEGISLATIONDATE',
        VehicleSideWallLeftWidth: 'VEHICLESIDEWALLLEFTWIDTH',
        VehicleSideWallRightWidth: 'VEHICLESIDEWALLRIGHTWIDTH',
        VehicleWheelbaseTheoreticalEndToAxle4Rear: 'VEHICLEWHEELBASETHEORETICALENDTOAXLE4REAR',
        VehicleTurningRadiusInner: 'VEHICLETURNINGRADIUSINNER',
        VehicleRearMinus1Gross: 'VEHICLEREARMINUS1GROSS',
        VehicleRearMinus2Gross: 'VEHICLEREARMINUS2GROSS',
        VehicleRearMinus3Gross: 'VEHICLEREARMINUS3GROSS',
        VehicleMainGross: 'VEHICLEMAINGROSS',
        VehicleRearPlus1Gross: 'VEHICLEREARPLUS1GROSS',
        VehicleRearPlus2Gross: 'VEHICLEREARPLUS2GROSS',
        VehicleRearPlus3Gross: 'VEHICLEREARPLUS3GROSS',
        VehicleFrontManufacturerRating: 'VEHICLEFRONTMANUFACTURERRATING',
        VehiclePusher1ManufacturerRating: 'VEHICLEPUSHER1MANUFACTURERRATING',
        VehiclePusher2ManufacturerRating: 'VEHICLEPUSHER2MANUFACTURERRATING',
        VehiclePusher3ManufacturerRating: 'VEHICLEPUSHER3MANUFACTURERRATING',
        VehicleRearRearManufacturerRating: 'VEHICLEREARREARMANUFACTURERRATING',
        VehicleTag1ManufacturerRating: 'VEHICLETAG1MANUFACTURERRATING',
        VehicleTag2ManufacturerRating: 'VEHICLETAG2MANUFACTURERRATING',
        VehicleTag3ManufacturerRating: 'VEHICLETAG3MANUFACTURERRATING',
        VehicleRearManufacturerRating: 'VEHICLEREARMANUFACTURERRATING',
        VehicleRearSpreadDistance: 'VEHICLEREARSPREADDISTANCE',
        VehicleRearRearSpreadDistance: 'VEHICLEREARREARSPREADDISTANCE',
        VehicleRearMinus1ToFirstMainAxle: 'VEHICLEREARMINUS1TOFIRSTMAINAXLE',
        CabWidth: 'CABWIDTH',
        TrackWidthVehicleFront: 'TRACKWIDTHVEHICLEFRONT',
        TrackWidthVehicleRear: 'TRACKWIDTHVEHICLEREAR',

        Trailer1Source: 'TRAILER1SOURCE',
        Trailer2Source: 'TRAILER2SOURCE',
        Trailer1RearSpreadDistance: 'TRAILER1REARSPREADDISTANCE',
        Trailer2RearSpreadDistance: 'TRAILER2REARSPREADDISTANCE',
        Trailer1SideWallLeftWidth: 'TRAILER1SIDEWALLLEFTWIDTH',
        Trailer1SideWallRightWidth: 'TRAILER1SIDEWALLRIGHTWIDTH',
        Trailer1FrontToStartOfTaper: 'TRAILER1FRONTTOSTARTOFTAPER',
        Trailer1FrontSpreadDistance: 'TRAILER1FRONTSPREADDISTANCE',
        CargoCarryingLength: 'CARGOCARRYINGLENGTH',
        ComVehGapBetweenVehicleAndTrailerBody: 'COMVEHGAPBETWEENVEHICLEANDTRAILERBODY',
        ComVehDrawbarVehRearMostAxleToAxle1TrailerFront: 'COMVEHDRAWBARVEHREARMOSTAXLETOAXLE1TRAILERFRONT',
        ComVehRearMostAxleToAxle1Trailer1Rear: 'COMVEHREARMOSTAXLETOAXLE1TRAILER1REAR',
        ComVehIntSemiTrailer1RearMostAxleToAxle1Trailer2: 'COMVEHINTSEMITRAILER1REARMOSTAXLETOAXLE1TRAILER2',
        ComVehHookliftRearOverhangTotal: 'COMVEHHOOKLIFTREAROVERHANGTOTAL',
        ComVehDrawbarGroup5Distance: 'COMVEHDRAWBARGROUP5DISTANCE',
        ComVehPupGroup3Distance: 'COMVEHPUPGROUP3DISTANCE',
        ComVehSemiGroup3Distance: 'COMVEHSEMIGROUP3DISTANCE',
        ComVehIntSemiGroup5Distance: 'COMVEHINTSEMIGROUP5DISTANCE',

        VehicleMinus1NoOfAxles: 'VEHICLEMINUS1NOOFAXLES',
        VehicleMinus1NoOfTyresPerAxle: 'VEHICLEMINUS1NOOFTYRESPERAXLE',
        VehicleMinus1WidthPerTyre: 'VEHICLEMINUS1WIDTHPERTYRE',
        VehicleMinus1Lifting: 'VEHICLEMINUS1LIFTING',
        VehicleMinus1Raised: 'VEHICLEMINUS1RAISED',
        VehicleMinus1Steering: 'VEHICLEMINUS1STEERING',

        VehicleMinus2NoOfAxles: 'VEHICLEMINUS2NOOFAXLES',
        VehicleMinus2NoOfTyresPerAxle: 'VEHICLEMINUS2NOOFTYRESPERAXLE',
        VehicleMinus2WidthPerTyre: 'VEHICLEMINUS2WIDTHPERTYRE',
        VehicleMinus2Lifting: 'VEHICLEMINUS2LIFTING',
        VehicleMinus2Raised: 'VEHICLEMINUS2RAISED',
        VehicleMinus2Steering: 'VEHICLEMINUS2STEERING',

        VehicleMinus3NoOfAxles: 'VEHICLEMINUS3NOOFAXLES',
        VehicleMinus3NoOfTyresPerAxle: 'VEHICLEMINUS3NOOFTYRESPERAXLE',
        VehicleMinus3WidthPerTyre: 'VEHICLEMINUS3WIDTHPERTYRE',
        VehicleMinus3Lifting: 'VEHICLEMINUS3LIFTING',
        VehicleMinus3Raised: 'VEHICLEMINUS3RAISED',
        VehicleMinus3Steering: 'VEHICLEMINUS3STEERING',

        UtilisationOfGVWRPercent: 'UTILISATIONOFGVWRPERCENT',
        UtilisationOfGCWRPercent: 'UTILISATIONOFGCWRPERCENT',
        UtilisationOfVehiclePermissiblePercent: 'UTILISATIONOFVEHICLEPERMISSIBLEPERCENT',
        UtilisationOfCombinationPermissiblePercent: 'UTILISATIONOFCOMBINATIONPERMISSIBLEPERCENT',
        SwingClearanceBetweenTruckTractorAndTrailer1: 'SWINGCLEARANCEBETWEENTRUCKTRACTORANDTRAILER1',
        SwingClearanceBetweenInterlinkTrailer1AndTrailer2: 'SWINGCLEARANCEBETWEENINTERLINKTRAILER1ANDTRAILER2',
        SwingClearanceBetweenRigidAndDrawbar: 'SWINGCLEARANCEBETWEENRIGIDANDDRAWBAR',
        SwingClearanceBetweenRigidAndPup: 'SWINGCLEARANCEBETWEENRIGIDANDPUP',
        VehiclePayloadCOGMM: 'VEHICLEPAYLOADCOGMM',
        VehiclePayloadCOGPercent: 'VEHICLEPAYLOADCOGPERCENT',
        Trailer1PayloadCOGMM: 'TRAILER1PAYLOADCOGMM',
        Trailer1PayloadCOGPercent: 'TRAILER1PAYLOADCOGPERCENT',
        Trailer2PayloadCOGMM: 'TRAILER2PAYLOADCOGMM',
        Trailer2PayloadCOGPercent: 'TRAILER2PAYLOADCOGPERCENT',
        VehicleBodyAdded: 'VEHICLEBODYADDED',
        Trailer1BodyAdded: 'TRAILER1BODYADDED',
        Trailer2BodyAdded: 'TRAILER2BODYADDED',

        VehicleFrontOuterTrackWidth: 'VEHICLEFRONTOUTERTRACKWIDTH',
        VehicleRearMainOuterTrackWidth: 'VEHICLEREARMAINOUTERTRACKWIDTH',
        VehicleRearMinus1OuterTrackWidth: 'VEHICLEREARMINUS1OUTERTRACKWIDTH',
        VehicleRearPlus1OuterTrackWidth: 'VEHICLEREARPLUS1OUTERTRACKWIDTH',

        VehicleWheelbaseTechnical: 'VEHICLEWHEELBASETECHNICAL',//only used as a rule context i.e. dimVehicleWheelbaseTechnical

        Trailer1FifthWheelOffset: 'TRAILER1FIFTHWHEELOFFSET',
        InteraxleVehicleFrontToVehicleRear: 'INTERAXLEVEHICLEFRONTTOVEHICLEREAR',
        VehicleEffectiveRearOverhang: 'VEHICLEEFFECTIVEREAROVERHANG',

        //2023.4
        VehicleEnergySource: 'VEHICLEENERGYSOURCE',
        LegislationRoadName: 'LEGISLATIONROADNAME',

        AppLimitVehicleRear: 'APPLIMITVEHICLEREAR',
        AppLimitVehicleRearMain: 'APPLIMITVEHICLEREARMAIN',
        AppLimitVehicleRearMinus1: 'APPLIMITVEHICLEREARMINUS1',
        AppLimitVehicleRearMinus2: 'APPLIMITVEHICLEREARMINUS2',
        AppLimitVehicleRearMinus3: 'APPLIMITVEHICLEREARMINUS3',
        AppLimitVehicleRearPlus1: 'APPLIMITVEHICLEREARPLUS1',
        AppLimitVehicleRearPlus2: 'APPLIMITVEHICLEREARPLUS2',
        AppLimitVehicleRearPlus3: 'APPLIMITVEHICLEREARPLUS3',
        AppLimitVehicleFront: 'APPLIMITVEHICLEFRONT',
        AppLimitVehicle: 'APPLIMITVEHICLE',
        AppLimitTrailer1Front: 'APPLIMITTRAILER1FRONT',
        AppLimitTrailer1Rear: 'APPLIMITTRAILER1REAR',
        AppLimitTrailer2Rear: 'APPLIMITTRAILER2REAR',
        
        //2023.5
        CombinationOverallWidth: 'COMBINATIONOVERALLWIDTH',
        VehicleOverallWidth: 'VEHICLEOVERALLWIDTH',
        SnowploughAdded: 'SNOWPLOUGHADDED',

        FrontUnderrunAUAdded: 'FRONTUNDERRUNAUADDED',
        DevicesForIndirectVisionAUAdded: 'DEVICESFORINDIRECTVISIONAUADDED',
        BrakeSystemsAUAdded: 'BRAKESYSTEMSAUADDED',
        LaneDepartureWarningAUAdded: 'LANEDEPARTUREWARNINGAUADDED',
        SideUnderrunProtectionAUAdded: 'SIDEUNDERRUNPROTECTIONAUADDED',
        ConspicuityMarkingsRearAUAdded: 'CONSPICUITYMARKINGSREARAUADDED',
        ConspicuityMarkingsSidesAUAdded: 'CONSPICUITYMARKINGSSIDESAUADDED',
        BlindSpotInfoSystemsAUAdded: 'BLINDSPOTINFOSYSTEMSAUADDED',
        FifthWheelAdded: 'FIFTHWHEELADDED',
        ShowSafety: 'SHOWSAFETY',
        AdvancedEmergencyBrakingAUAdded: 'ADVANCEDEMERGENCYBRAKINGAUADDED',

        AppLimitVehicleBridge: 'APPLIMITVEHICLEBRIDGE',
        AppLimitCombinationBridge: 'APPLIMITCOMBINATIONBRIDGE',
        CombinationBridgeFormulaUsed: 'COMBINATIONBRIDGEFORMULAUSED',

        ComVehCraneAdded: 'COMVEHCRANEADDED',
        VehiclePayload: 'VEHICLEPAYLOAD',
        Trailer1Payload: 'TRAILER1PAYLOAD',
        Trailer2Payload: 'TRAILER2PAYLOAD',
        KingpinToRearOfTrailer1: 'KINGPINTOREAROFTRAILER1',
        KingpinToRearOfTrailer2: 'KINGPINTOREAROFTRAILER2',

        VehicleFifthWheelOffset: 'VEHICLEFIFTHWHEELOFFSET',

        //2024.2
        ReverseGearStartability: 'REVERSEGEARSTARTABILITY',
        CDLRequiredUS: 'CDLREQUIREDUS',
        ParkPAWL: 'PARKPAWL',
        AppEnvironment: 'APPENVIRONMENT',
        
    };
    variableTypes['BridgeDistanceRounded0.1M'] = 'BRIDGEDISTANCEROUNDED0.1M';

    //2024.2
    variableTypes['1stGearStartability'] = '1STGEARSTARTABILITY';
    variableTypes['1stGearGradeability'] = '1STGEARGRADEABILITY';

    var utilLegislationAttributeObjectCode = {
        commandTypes: commandTypes,
        variableTypes: variableTypes
    };

    export default utilLegislationAttributeObjectCode;
